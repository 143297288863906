import React from "react";
import { Row, Col, useI18n, Button } from "@maxeb/admin-ui";

export default function I18nChangeLanguage() {
  const i18n = useI18n("example");
  return (
    <Row>
      <Col xs="rest">
        Current Languag: <b>{i18n.language}</b>
      </Col>
      <Button
        xs="100px"
        primary
        onClick={() => {
          i18n.load(i18n.language === "en" ? "de" : "en");
        }}
      >
        Change
      </Button>
    </Row>
  );
}
