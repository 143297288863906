import React from "react";
import {
  faAddressCard,
  faArrowLeft,
  faBalloon,
  faCar,
  faHouse,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AdminManagedLayout, AdminManagedLayoutConfig } from "@maxeb/admin-ui";
import Logo from "../Logo";

const config: AdminManagedLayoutConfig = [
  {
    section: "section 1",
    elements: [
      {
        label: "Back",
        icon: <FontAwesomeIcon icon={faArrowLeft} />,
        path: "/",
        content: <div>Content for Link 1</div>,
      },
      {
        label: "Link 1",
        icon: <FontAwesomeIcon icon={faHouse} />,
        path: "/layouts/example/adminpanel",
        content: <div>Content for Link 1</div>,
      },
      {
        label: "Link 2",
        icon: <FontAwesomeIcon icon={faBalloon} />,
        path: "/layouts/example/adminpanel/link2",
        content: <div>Content for Link 2</div>,
      },
    ],
  },
  {
    section: "section 2",
    elements: [
      {
        label: "Link List",
        icon: <FontAwesomeIcon icon={faAddressCard} />,
        links: [
          {
            label: "List link 1",
            path: "/layouts/example/adminpanel/listlink1",
            content: <div>Content for List link 1</div>,
          },
          {
            label: "List link 2",
            path: "/layouts/example/adminpanel/listlink2",
            content: <div>Content for List link 2</div>,
          },
        ],
      },
    ],
  },
  {
    section: "section 3",
    elements: [
      {
        label: "Link List",
        collapseAble: true,
        icon: <FontAwesomeIcon icon={faCar} />,
        links: [
          {
            label: "List link 1",
            path: "/layouts/example/adminpanel/listlink3",
            content: <div>Content for List link 3</div>,
          },
          {
            label: "List link 2",
            path: "/layouts/example/adminpanel/listlink4",
            content: <div>Content for List link 4</div>,
          },
        ],
      },
    ],
  },
];

export default function AdminManagedLayoutExample() {
  return (
    <AdminManagedLayout
      logo={<Logo />}
      topbar="Topbar Content"
      config={config}
    />
  );
}
