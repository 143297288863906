import React from "react";
import { Button, Form } from "@maxeb/admin-ui";

export default function FormExample() {
  return (
    <Form vertical spacing={16} root={0}>
      <Button
        xs="120px"
        primary
        onClick={() => {
          alert("Clicked on primary!");
        }}
      >
        Primary
      </Button>
      <Button
        xs="120px"
        secondary
        onClick={() => {
          alert("Clicked on secondary!");
        }}
      >
        Secondary
      </Button>
      <Button
        xs="120px"
        tertiary
        onClick={() => {
          alert("Clicked on tertiary!");
        }}
      >
        Tertiary
      </Button>
      <Button
        xs="120px"
        success
        onClick={() => {
          alert("Clicked on success!");
        }}
      >
        Success
      </Button>
      <Button
        xs="120px"
        warning
        onClick={() => {
          alert("Clicked on warning!");
        }}
      >
        Warning
      </Button>
      <Button
        xs="120px"
        danger
        onClick={() => {
          alert("Clicked on danger!");
        }}
      >
        Danger
      </Button>
    </Form>
  );
}
