import React, { useState } from "react";
import { NumberInput, Form } from "@maxeb/admin-ui";

export default function NumberInputExample() {
  const [value, setValue] = useState<number | undefined>();
  return (
    <Form>
      <NumberInput label="NumberInput" value={value} onChange={setValue} />
    </Form>
  );
}
