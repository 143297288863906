import React from "react";
import { faBook } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { List, ListItem } from "@maxeb/admin-ui";

export default function ListsExample() {
  return (
    <List icon={<FontAwesomeIcon icon={faBook} />} label="Liste">
      <ListItem>Element 1</ListItem>
      <ListItem>Element 2</ListItem>
      <ListItem>Element 3</ListItem>
    </List>
  );
}
