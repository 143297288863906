import React from "react";
import { useClasses, ITheme } from "@maxeb/admin-ui";

interface MyBlueDivFCProps {}

const styles = (theme: ITheme) => ({
  myBlueDiv: {
    backgroundColor: "blue",
    color: "white",
    padding: "32px 0px",
    textAlign: "center",
  },
});

export default function MyBlueDivFC(props: MyBlueDivFCProps) {
  const classes = useClasses(styles, props);
  return <div className={classes.myBlueDiv}>Hello I am a blue div.</div>;
}
