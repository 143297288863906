import React from "react";
import { withClasses, IWithClasses, ITheme } from "@maxeb/admin-ui";

interface MyBlueDivCCProps {}

const styles = (theme: ITheme) => ({
  myBlueDiv: {
    backgroundColor: "blue",
    color: "white",
    padding: "32px 0px",
    textAlign: "center",
  },
});
//wrap propertie's interface with injection propertie's interface
type PropsType = IWithClasses<MyBlueDivCCProps>;

class MyBlueDivCC extends React.Component<PropsType, {}> {
  render() {
    const { classes } = this.props;
    return <div className={classes.myBlueDiv}>Hello I am a blue div.</div>;
  }
}
//wrap component with container
export default withClasses<MyBlueDivCCProps>(styles, MyBlueDivCC);
