import classNames from "classnames";
import React from "react";
import { Block, Typo, Row, Col, useClasses, ITheme } from "@maxeb/admin-ui";

interface StringOrObject {
  [key: string]: string | StringOrObject | [string | StringOrObject];
}

export interface IDocumetationBlock {
  label?: React.ReactNode;
  description?: React.ReactNode;
  requires?: React.ReactNode[];
  properties?: {
    name: React.ReactNode;
    required?: boolean;
    default?: React.ReactNode;
    type: (string | StringOrObject | [string | StringOrObject])[];
    description?: React.ReactNode;
  }[];
  extends?: { label: string; to: string }[];
  language?: "bash" | "css" | "typescript";
  steps?: { label?: React.ReactNode; code?: string }[];
  exampleDescrtiption?: React.ReactNode;
  exampleCode?: string;
  example?: React.ReactNode;
}

const styles = (theme: ITheme) => ({
  propertiesList: {
    paddingLeft: "0px",
    listStyle: "none",
  },
  propertiesListElement: {
    paddingBottom: "20px",
    marginBottom: "20px",
    borderBottom: "1px solid " + theme.palette.get("grey"),
  },
  requiresList: {
    marginBottom: 0,
    paddingLeft: 20,
  },
  typeList: {
    margin: 0,
    padding: "0 0 0 30px",
  },
  required: {
    color: theme.palette.get("danger"),
    fontWeight: 400,
  },
  label: {
    display: "block",
    textTransform: "uppercase",
    fontSize: "0.8rem",
    fontWeight: "600",
  },
  success: {
    color: theme.palette.get("success"),
  },
  warning: {
    color: theme.palette.get("warning"),
  },
  danger: {
    color: theme.palette.get("danger"),
  },
  intend: {
    display: "block",
    paddingLeft: "15px",
  },
  key: {
    display: "inline-block",
    paddingRight: "4px",
  },
});

function renderType(
  type: string | StringOrObject | [string | StringOrObject],
  classes: { [key: string]: string }
) {
  const isArray = Array.isArray(type);
  const toRender = isArray ? type[0] : type;

  if (typeof toRender === "object")
    return (
      <span>
        {"{"}
        {Object.entries(toRender).map(([key, value]) => {
          return (
            <span className={classes.intend} key={`object-${key}`}>
              <i className={classes.key}>{key}:</i>{" "}
              {typeof value === "object" ? renderType(value, classes) : value}
            </span>
          );
        })}
        {"}"}
        {isArray ? "[]" : ""}
      </span>
    );
  return toRender + (isArray ? "[]" : "");
}

export default function DocumentationComponent(props: IDocumetationBlock) {
  const classes = useClasses(styles);
  const properties = (props?.properties || []).map((item) => ({
    ...item,
    type: (
      <ul className={classes.typeList}>
        {(item?.type || []).map((type, index) => (
          <li key={`type-${index}`}>{renderType(type, classes)}</li>
        ))}
      </ul>
    ),
  }));
  return (
    <React.Fragment>
      {typeof props.label !== "undefined" && (
        <Typo variant="section">{props.label}</Typo>
      )}

      <Block>
        <Row
          root={0}
          vertical
          spacing={16}
          horizontalAlign="right"
          verticalAlign="bottom"
        >
          {typeof props.description !== "undefined" && (
            <Col>
              <Typo variant="h3" margin="none" color="primary">
                Description
              </Typo>
              <Typo variant="p" margin="8px 0px 0px 0px">
                {props.description}
              </Typo>
            </Col>
          )}
          {typeof props.requires !== "undefined" && (
            <Col>
              <Typo variant="h3" margin="none" color="primary">
                Requires
              </Typo>
              <ul className={classes.requiresList}>
                {props.requires.map((item, index) => (
                  <li key={`req-${index}`}>{item}</li>
                ))}
              </ul>
            </Col>
          )}
          {typeof props.properties !== "undefined" && (
            <Col>
              <Typo variant="h3" margin="none" color="primary">
                Properties
              </Typo>
              {typeof props.extends !== "undefined" && (
                <Typo variant="p">
                  Extends:{" "}
                  {props.extends.map((item, index) => (
                    <React.Fragment key={`extends-${index}-${item.to}`}>
                      <Typo variant="a" to={item.to}>
                        {item.label}
                      </Typo>
                      {index !== (props.extends?.length || 1) - 1 && ", "}
                    </React.Fragment>
                  ))}
                </Typo>
              )}
              <ul className={classes.propertiesList}>
                {properties.map((property, index) => (
                  <li
                    key={`property-${index}`}
                    className={classNames([
                      index + 1 < properties.length &&
                        classes.propertiesListElement,
                    ])}
                  >
                    <b>
                      {property.name}
                      {property.required && (
                        <span className={classes.required}> required</span>
                      )}
                    </b>
                    <Typo variant="p">{property.description} </Typo>
                    <Row parts={10}>
                      <Col lg={2}>
                        <Typo variant="p">
                          <span
                            className={classNames([
                              classes.label,
                              classes.danger,
                            ])}
                          >
                            required
                          </span>
                          {property.required ? "yes" : "no"}
                        </Typo>
                      </Col>

                      <Col lg={3}>
                        <Typo variant="p">
                          <span
                            className={classNames([
                              classes.label,
                              classes.warning,
                            ])}
                          >
                            default
                          </span>
                          <span className={classes.intend}>
                            {property.default || "-"}
                          </span>
                        </Typo>
                      </Col>
                      <Col lg={5}>
                        <Typo variant="p">
                          <span
                            className={classNames([
                              classes.label,
                              classes.success,
                            ])}
                          >
                            type
                          </span>
                        </Typo>
                        {property.type}
                      </Col>
                    </Row>
                  </li>
                ))}
              </ul>
            </Col>
          )}
        </Row>
      </Block>
    </React.Fragment>
  );
}
