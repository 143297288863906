import React from "react";
import { Table } from "@maxeb/admin-ui";

const columns = [
  { label: "Name", field: "name" },
  { label: "From >=", field: "from" },
  { label: "To <", field: "to" },
];

const data = [
  {
    name: "xs",
    from: "0px",
    to: "576px",
  },
  {
    name: "sm",
    from: "576px",
    to: "768px",
  },
  {
    name: "md",
    from: "768px",
    to: "992px",
  },
  {
    name: "lg",
    from: "992px",
    to: "1200px",
  },
  {
    name: "xl",
    from: "1200px",
    to: "infinity",
  },
];

export default function ColorTable() {
  return <Table columns={columns} data={data} />;
}
