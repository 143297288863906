import React from "react";
import { Block, Col, Row, StateHandler, Typo } from "@maxeb/admin-ui";

const rndContent = (
  <Block>
    <Typo variant="h3" color="primary" margin="none">
      RND Content
    </Typo>
    <Typo variant="p">
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
      eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
      voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet
      clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
      amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
      nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed
      diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
      Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor
      sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
      diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
      erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
      rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum
      dolor sit amet. Duis autem vel eum iriure dolor in hendrerit in vulputate
      velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis
      at vero eros et accumsan et iusto odio dignissim qui blandit praesent
      luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem
      ipsum dolor sit amet,
    </Typo>
  </Block>
);

export default function ExampleStateHandler() {
  return (
    <>
      <Typo variant="h1">StateHandler</Typo>
      <Row root={0} spacing={16} vertical>
        <Col lg={7}>
          <Typo variant="section">State = ok</Typo>
          <StateHandler state="ok">{rndContent}</StateHandler>
          <Typo variant="section">State = pending</Typo>
          <StateHandler state="pending">{rndContent}</StateHandler>
          <Typo variant="section">State = error</Typo>
          <StateHandler state="error">{rndContent}</StateHandler>
        </Col>
      </Row>
    </>
  );
}
