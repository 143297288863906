import React, { useState } from "react";
import {
  Authenticator,
  Button,
  useSession,
  StateHandler,
  Typo,
  Row,
} from "@maxeb/admin-ui";
import { IDP } from "@datenbanker/idp-sdk";
import CodeViewer from "../CodeViewer";

export default function FormDoc() {
  const [session, setSession] = useSession(null);
  const [status, setStatus] = useState<"ok" | "pending" | "error">("ok");

  if (session === null)
    return <Authenticator session={session} setSession={setSession} />;

  const json = JSON.stringify(session).replace(/,/g, ",\n  ");
  const code = `{\n  ${json.substring(1, json.length - 1)}\n}`;
  return (
    <StateHandler state={status}>
      <Row spacing={16} vertical root={0} horizontalAlign="right">
        <Typo variant="h3" color="primary" margin="none">
          Session
        </Typo>

        <CodeViewer language="typescript">{code}</CodeViewer>
        <Button
          xs="120px"
          danger
          onClick={async () => {
            setStatus("pending");
            await IDP.logout();
            setStatus("ok");
            setSession(null);
          }}
        >
          Logout
        </Button>
      </Row>
    </StateHandler>
  );
}
