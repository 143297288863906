import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";
import ListExample from "./ListExample";
import ListExampleCollapsable from "./ListExampleCollapsable";

export default function ListItemtDoc() {
  return (
    <>
      <Typo variant="h1">ListItem</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description={<>Child component for List item.</>}
            properties={[
              {
                name: "children",
                default: "undefined",
                type: ["React.ReactNode", "undefined"],
                description: "Content you want to have in the ListItem.",
              },
            ]}
          />
          <Example
            label="Example"
            example={<ListExample />}
            language="typescript"
            code={
              'import React from "react";\nimport { faBook } from "@fortawesome/pro-light-svg-icons";\nimport { FontAwesomeIcon } from "@fortawesome/react-fontawesome";\nimport { List, ListItem } from "@maxeb/admin-ui";\n\nexport default function ListsExample() {\n  return (\n    <List icon={<FontAwesomeIcon icon={faBook} />} label="Liste">\n      <ListItem>Element 1</ListItem>\n      <ListItem>Element 2</ListItem>\n      <ListItem>Element 3</ListItem>\n    </List>\n  );\n}'
            }
          />
          <Example
            label="Example collapseAble & open"
            example={<ListExampleCollapsable />}
            language="typescript"
            code={
              'import React from "react";\nimport { faBook } from "@fortawesome/pro-light-svg-icons";\nimport { FontAwesomeIcon } from "@fortawesome/react-fontawesome";\nimport { List, ListItem } from "@maxeb/admin-ui";\n\nexport default function ListExampleCollapsable() {\n  return (\n    <List\n      icon={<FontAwesomeIcon icon={faBook} />}\n      label="Liste"\n      collapseAble\n      open\n    >\n      <ListItem>Element 1</ListItem>\n      <ListItem>Element 2</ListItem>\n      <ListItem>Element 3</ListItem>\n    </List>\n  );\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
