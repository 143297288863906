import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";
import SectionExample from "./SectionExample";

export default function ListDoc() {
  return (
    <>
      <Typo variant="h1">SidebarSection</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description={
              <>
                A section for the sidebar elment. Will wrap children into a
                section and gives it a label.
              </>
            }
            properties={[
              {
                name: "label",
                default: "undefined",
                type: ["string", "undefined"],
                description: "Label for section.",
              },
              {
                name: "children",
                default: "undefined",
                type: ["React.ReactNode", "undefined"],
                description: "Content for section.",
              },
            ]}
          />
          <Example
            label="Example"
            example={<SectionExample />}
            language="typescript"
            code={
              'import React from "react";\nimport { Sidebar, SidebarSection } from "@maxeb/admin-ui";\n\nexport default function SidebarSectionExample() {\n  return (\n    <Sidebar>\n      <SidebarSection label="Label">Content</SidebarSection>\n    </Sidebar>\n  );\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
