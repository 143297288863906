import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";
import StateHandlerExample from "./StateHandlerExample";

export default function AuthenticationDoc() {
  return (
    <>
      <Typo variant="h1">StateHandler</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description={
              <>
                This component will handle states event. Just wrap it around
                content and set a state, it will overlay visuals.
              </>
            }
            requires={[<>I18n configured.</>]}
            properties={[
              {
                name: "state",
                required: true,
                type: ["ok", "pending ", "success", "error"],
                description:
                  "Sets the overlay for content. Ok will not produce any overlay.",
              },
              {
                name: "onErrorReset",
                default: "undefined",
                type: ["() => void", "undefined"],
                description:
                  "If set and if state is error will provide a button to user for state reset.",
              },
              {
                name: "children",
                required: true,
                type: ["React.ReactNode"],
                description:
                  "Content which should be overlayed by state (if not ok).",
              },
              {
                name: "translationGroup",
                default: "stateHandler",
                type: ["string", "undefined"],
                description:
                  "The i18n group in your translation files, by default it searchs for stateHandler translation group.",
              },
            ]}
          />
          <Example
            label="Example"
            example={<StateHandlerExample />}
            language="typescript"
            code={
              'import React from "react";\nimport { Block, StateHandler, Typo } from "@maxeb/admin-ui";\n\nconst rndContent = (\n  <Block>\n    <Typo variant="h3" color="primary" margin="none">\n      RND Content\n    </Typo>\n    <Typo variant="p">\n      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy\n      eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam\n      voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet\n      clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit\n      amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam\n      nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed\n      diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.\n      Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor\n      sit amet.\n    </Typo>\n  </Block>\n);\n\nexport default function StateHandlerExample() {\n  return <StateHandler state="pending">{rndContent}</StateHandler>;\n}'
            }
          />
          <Example
            label="I18n translation json"
            language="typescript"
            code={
              '{\n    "stateHandler": {\n        "a": "A",\n        "unknown Error": "unknown error",\n        "occurred": "occurred"\n    }\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
