import React from "react";
import { Col, Row } from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScroll } from "@fortawesome/pro-light-svg-icons";

export default function Logo() {
  return (
    <Row spacing={16} root={0}>
      <Col xs="auto">
        <FontAwesomeIcon icon={faScroll} style={{ fontSize: "2.4rem" }} />
      </Col>
      <Col xs="calc(100% - 55px)">
        <div style={{ fontSize: "1.5rem", marginTop: "2px" }}>
          Dokumentation
        </div>
      </Col>
    </Row>
  );
}
