import React from "react";
import { Typo } from "@maxeb/admin-ui";
import Form from "./ExampleForm";

export interface IForms {}

export default function Forms(props: IForms) {
  return (
    <>
      <h1>Forms</h1>

      <Typo variant="section">Inline</Typo>
      <Form inline />
      <Typo variant="section">floating</Typo>
      <Form />
      <Typo variant="section">open</Typo>
      <Form open />
    </>
  );
}
