import React from "react";
import {
  Sidebar,
  SidebarList,
  SidebarSection,
  SidebarLink,
  SidebarListLink,
  useRouter,
} from "@maxeb/admin-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlag,
  faPenToSquare,
  faEarth,
  faList,
  faPaintRoller,
  faChartLine,
  faObjectsAlignTop,
  faSidebar,
  faLock,
  faBatteryBolt,
} from "@fortawesome/pro-light-svg-icons";
import Logo from "./Logo";

export interface INavigation {
  onClose?: () => void;
}

const content = [
  {
    label: "General",
    elements: [
      {
        icon: faFlag,
        label: "Getting started",
        open: true,
        collapseAble: true,
        links: [
          { label: "Installation", path: "/installation" },
          { label: "Initialisation", path: "/initialisation" },
          { label: "Overview", path: "/overview" },
        ],
      },
      {
        icon: faPaintRoller,
        label: "Customization",
        path: "/customization",
      },
      {
        icon: faEarth,
        label: "I18n",
        path: "/i18n",
      },
    ],
  },
  {
    label: "Layouts",
    elements: [
      {
        icon: faChartLine,
        label: "Admin",
        open: false,
        collapseAble: true,
        links: [
          { label: "Example", path: "/layouts/example/adminpanel" },
          {
            label: "AdminLayout",
            path: "/layouts/adminpanel/adminlayout",
          },
          {
            label: "AdminManagedLayout",
            path: "/layouts/adminpanel/adminmanagedlayout",
          },
        ],
      },
      {
        icon: faLock,
        label: "Singel page auth",
        open: false,
        collapseAble: true,
        links: [
          { label: "Example", path: "/layouts/example/singelpageauth" },
          {
            label: "SignOnLayout",
            path: "/layouts/singelpageauth/singelpageauthlayout",
          },
        ],
      },
      /*{
        icon: faCartShopping,
        label: "Store",
        open: false,
        collapseAble: true,
        links: [
          { label: "Example", path: "/layouts/example/store" },
          { label: "StoreLayout", path: "/layouts/store/storelayout" },
          {
            label: "StoreManagedLayout",
            path: "/layouts/store/storemanagedlayout",
          },
        ],
      }, */
    ],
  },
  {
    label: "Components",
    elements: [
      {
        icon: faObjectsAlignTop,
        label: "Flex",
        open: false,
        collapseAble: true,
        links: [
          { label: "Example", path: "/flex/example" },
          { label: "Row", path: "/flex/row" },
          { label: "Col", path: "/flex/col" },
        ],
      },
      {
        icon: faPenToSquare,
        label: "Forms",
        open: false,
        collapseAble: true,
        links: [
          { label: "Example", path: "/forms/example" },
          { label: "Form", path: "/forms/form" },
          { label: "TextInput", path: "/forms/textinput" },
          { label: "NumberInput", path: "/forms/numberinput" },
          { label: "DateInput", path: "/forms/dateinput" },
          { label: "Select", path: "/forms/select" },
          { label: "Radio", path: "/forms/radio" },
          { label: "RadioItem", path: "/forms/radioitem" },
          { label: "Checkbox", path: "/forms/checkbox" },
          { label: "UploadArea", path: "/forms/uploadarea" },
          { label: "Button", path: "/forms/button" },
        ],
      },
      {
        icon: faList,
        label: "Lists",
        open: false,
        collapseAble: true,
        links: [
          { label: "Example", path: "/lists/example" },
          { label: "List", path: "/lists/list" },
          { label: "ListItem", path: "/lists/listitem" },
        ],
      },
      {
        icon: faSidebar,
        label: "Sidebar",
        open: false,
        collapseAble: true,
        links: [
          { label: "Example", path: "/sidebar/example" },
          { label: "Sidebar", path: "/sidebar/sidebar" },
          { label: "SidebarSection", path: "/sidebar/section" },
          { label: "SidebarList", path: "/sidebar/list" },
          { label: "SidebarListLink", path: "/sidebar/listlink" },
        ],
      },
      {
        icon: faLock,
        label: "Authentication",
        open: false,
        collapseAble: true,
        links: [
          { label: "Example", path: "/authentication/example" },
          { label: "Authenticator", path: "/authentication/authenticator" },
        ],
      },
      {
        icon: faBatteryBolt,
        label: "StateHandler",
        open: false,
        collapseAble: true,
        links: [
          { label: "Example", path: "/statehandler/example" },
          { label: "StateHandler", path: "/statehandler/statehandler" },
        ],
      },
      /*       {
        icon: faTable,
        label: "Tables",
        open: false,
        collapseAble: true,
        links: [
          { label: "Example", path: "/tables/example" },
          { label: "Table", path: "/tables/table" },
        ],
      },
       */
    ],
  },
];

export default function Navigation(props: INavigation) {
  const [router] = useRouter();
  const currentPath = router.path;

  return (
    <Sidebar logo={<Logo />} onClose={props.onClose}>
      {content.map((section, pos) => (
        <SidebarSection label={section.label} key={"content-" + pos}>
          {section.elements.map((element, index) => {
            if (element.path)
              return (
                <SidebarLink
                  key={"content-" + pos + "-" + index}
                  active={currentPath === element.path}
                  icon={<FontAwesomeIcon icon={element.icon} />}
                  to={element.path}
                >
                  {element.label}
                </SidebarLink>
              );
            else
              return (
                <SidebarList
                  icon={<FontAwesomeIcon icon={element.icon} />}
                  label={element.label}
                  collapseAble={element.collapseAble}
                  open={element.open}
                  key={"content-" + pos + "-" + index}
                >
                  {element.links?.map((link, linkPos) => (
                    <SidebarListLink
                      active={currentPath === link.path}
                      to={link.path}
                      key={"content-" + pos + "-" + index + "-" + linkPos}
                    >
                      {link.label}
                    </SidebarListLink>
                  ))}
                </SidebarList>
              );
          })}
        </SidebarSection>
      ))}
    </Sidebar>
  );
}
