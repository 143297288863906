import React from "react";
import { ICol } from "@maxeb/admin-ui";
import { Prism } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

export interface ICodeViewer extends ICol {
  language: "typescript" | "css" | "bash";
  children?: React.ReactNode;
}

export default function CodeViewer(props: ICodeViewer) {
  if (props.language === "typescript")
    return (
      <Prism
        customStyle={{
          margin: 0,
          borderRadius: "2px",
          background: "#212a37",
          padding: "5px 8px 6px 8px",
        }}
        language="typescript"
        style={atomDark}
      >
        {props.children}
      </Prism>
    );
  else
    return (
      <SyntaxHighlighter
        customStyle={{
          margin: 0,
          borderRadius: "2px",
          background: "#212a37",
        }}
        language={props.language}
        style={atomOneDark}
      >
        {props.children}
      </SyntaxHighlighter>
    );
}
