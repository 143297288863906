import React from "react";
import { Typo, Row, Col } from "@maxeb/admin-ui";

import DocumentationBlock, { IDocumetationBlock } from "./DocumentationBlock";

export interface ILists {}

const content: IDocumetationBlock[] = [
  {
    label: "ADD THEME TO PROJECT",
    language: "bash",
    description:
      "Since this theme is in a private pkg you need to obtain an auth-token first.",
    steps: [
      {
        label:
          "Go to the react project you want to add the theme to. Note react and react-dom versions need to be >= 17.",
        code: "cd ../<YOUR_PROJECT_PATH>",
      },
      {
        label: (
          <>
            In root of your project create a file called <i>.npmrc</i>.
          </>
        ),
        code: "touch .npmrc",
      },
      {
        label: (
          <>
            <b>IMPORTANT</b>: make sure <i>.npmrc</i> is listed in your{" "}
            <i>.gitignore</i>. You dont want to publish your auth-token.
          </>
        ),
        code: ".npmrc",
      },
      {
        label: (
          <>
            Add following line in <i>.npmrc</i> and replace {"<TOKEN>"} with
            your auth-token.
          </>
        ),
        code: "//registry.npmjs.org/:_authToken=<TOKEN>",
      },
      {
        label: <>Install Theme.</>,
        code: "npm i -S @maxeb/admin-ui",
      },
      {
        label: <>Link datenbanker packges. Won't be neccessary soon.</>,
        code: "npm link @datenbanker/connector @datenbanker/idp-sdk",
      },
    ],
  },
];

export default function Lists(props: ILists) {
  return (
    <>
      <Typo variant="h1">Installation</Typo>
      <Row root={0} spacing={16} vertical>
        <Col lg={7}>
          {content.map((item, number) => (
            <DocumentationBlock {...item} />
          ))}
        </Col>
      </Row>
    </>
  );
}
