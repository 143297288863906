import React, { useState } from "react";
import { Radio, Form, RadioItem } from "@maxeb/admin-ui";

export default function RadioExample() {
  const [value, setValue] = useState<string | undefined>();

  return (
    <Form spacing={16} root={0}>
      <Radio label="Select" inline value={value} onChange={setValue}>
        <RadioItem sm={5} lg={10 / 4} label="Option 1" value="option1" />
        <RadioItem sm={5} lg={10 / 4} label="Option 2" value="option2" />
        <RadioItem sm={5} lg={10 / 4} label="Option 3" value="option3" />
        <RadioItem sm={5} lg={10 / 4} label="Option 4" value="option4" />
      </Radio>
    </Form>
  );
}
