const fieldProperties = [
  {
    name: "label",
    default: "undefined",
    type: ["string", "undefined"],
    description: "Sets the name of the field and its label.",
  },
  {
    name: "readOnly",
    default: "false",
    type: ["boolean", "undefined"],
    description: "Toggles fields readOnly design and the trigger of onChange",
  },
  {
    name: "open",
    default: "false",
    type: ["boolean", "undefined"],
    description:
      "If true label will stay above the input and stops floating. No function if inline is set to true.",
  },
  {
    name: "inline",
    default: "false",
    type: ["boolean", "undefined"],
    description:
      "Label will only be visible if inline value is empty or false, but fields need less space since label is not floating above them.",
  },
  {
    name: "info",
    default: "undefined",
    type: ["string", "undefined"],
    description:
      "Info text shown below input field. Will not be shown if error is set.",
  },
  {
    name: "error",
    default: "undefined",
    type: ["string", "undefined"],
    description:
      "Error text shown below input field. Will also trigger error highlighting.",
  },
  {
    name: "autoComplete",
    default: "undefined",
    type: ['"on"', '"off"', "undefined"],
    description: "Sets autoCompolete attribute on a field.",
  },
  {
    name: "onClick",
    default: "() => {}",
    type: ["() => void", "undefined"],
    description:
      "A function which triggers whenever there is a click on the field.",
  },
  {
    name: "onFocus",
    default: "() => {}",
    type: ["() => void", "undefined"],
    description: "A function which triggers when the field is getting focused.",
  },
  {
    name: "onBlur",
    default: "() => {}",
    type: ["() => void", "undefined"],
    description:
      "A function which triggers when something else is getting focused.",
  },
];

export default fieldProperties;
