import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";
import RowCol from "../examples/RowCol";

export interface IFlex {}

export default function RowDoc(props: IFlex) {
  return (
    <>
      <Typo variant="h1">Row</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description="This component together with the Col component is used as an easy way to allign elements in an row and column arragnement. These components make use of the css flex properties. The Row component is a wrapper component for the Col. It's main target is to handle basic alignments and paddings between and around Cols."
            properties={[
              {
                name: "spacing",
                default: "0",
                type: ["number"],
                description: "Space between child elements.",
              },
              {
                name: "vertical",
                default: "false",
                type: ["boolean"],
                description: "Apply space between elements also vertical.",
              },
              {
                name: "root",
                default: "Value of spacing",
                type: [
                  "number",
                  {
                    "top?": "number",
                    "left?": "number",
                    "bottom?": "number",
                    "right?": "number",
                  },
                ],
                description:
                  "Sets padding of root element which wraps row. Only set this value if you already have an element around your row with a padding which is atleast half the size of spacing property. This is neccessary since rows work with negative margin.",
              },
              {
                name: "parts",
                default: "10",
                type: ["number"],
                description:
                  "Max number of columns in a row. Widths of columns will be relative to this value. I.e. if parts value is 10 and a column has a width of 5 it will be half the width of the row. colWidth in % = colWidthValue / parts * 100",
              },
              {
                name: "children",
                default: "undefined",
                type: ["React.ReactNode"],
                description:
                  "Columns to be displayed. If any child is not a column element it will be wrapped with a column element and properties will be forwarded to column. Therefore children of rows, that are not a Col component, should allways extend the ICol interface in theire properties interface.",
              },
              {
                name: "stretch",
                default: "false",
                type: ["boolean"],
                description: "Stretches row to the size of the parent element.",
              },
              {
                name: "horizontalAlign",
                default: "left",
                type: [
                  '"left"',
                  '"right"',
                  '"center"',
                  '"space-between"',
                  '"space-around"',
                ],
                description: "Aligns child elements horizontal.",
              },
              {
                name: "verticalAlign",
                default: "top",
                type: [
                  '"top"',
                  '"bottom"',
                  '"center"',
                  '"baseline"',
                  '"stretch"',
                ],
                description: "Aligns child elements vertical.",
              },

              {
                name: "flow",
                default: "wrap row",
                type: ["string"],
                description: "Overwrites css property felx-flow.",
              },
              {
                name: "override",
                default: "top",
                type: [{ "root?": "string", "row?": "string" }],
                description:
                  "Override property for css class injections. See customization section for more info.",
              },
            ]}
          />
          <Example
            label="Example"
            example={<RowCol />}
            language="typescript"
            code={
              'import React from "react";\nimport { useClasses, ITheme, Block, Col, Row, Typo } from "@temeium/uui";\n\nconst styles = (theme: ITheme) => ({\n  border: {\n    border: "1px solid " + theme.palette.get("primary"),\n  },\n});\n\nexport default function RowCol() {\n  const classes = useClasses(styles, props);\n  return (\n    <>\n      <Typo variant="p" color="primary">\n        Row\n      </Typo>\n      <Row vertical spacing={16} root={8} override={{ row: classes.border }}>\n        <Col md={5} lg={10 / 3}>\n          <Block>\n            Col\n            <br />\n            md=5\n            <br />\n            lg=10 / 3\n          </Block>\n        </Col>\n        <Col md={5} lg={10 / 3}>\n          <Block>\n            Col\n            <br />\n            md=5\n            <br />\n            lg=10 / 3\n          </Block>\n        </Col>\n        <Col md={5} lg={10 / 3}>\n          <Block>\n            Col\n            <br />\n            md=5\n            <br />\n            lg=10 / 3\n          </Block>\n        </Col>\n      </Row>\n    </>\n  );\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
