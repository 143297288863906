import React from "react";
import { Col, Row, Typo, useClasses, ITheme, Block } from "@maxeb/admin-ui";

export interface IFlex {}

const styles = (theme: ITheme) => ({
  row: {
    border: "1px solid " + theme.palette.get("primary"),
  },
});

export default function Forms(props: IFlex) {
  const classes = useClasses(styles);
  return (
    <>
      <h1>Flex</h1>
      <Typo variant="section">ROW options</Typo>
      <Typo variant="p">vertical, spacing=32 root=0</Typo>
      <Typo variant="p" color="primary">
        Row
      </Typo>
      <Row vertical spacing={16} root={0} override={{ row: classes.row }}>
        <Col xs={5}>
          <Block>
            Col
            <br />
            xs=5
          </Block>
        </Col>
        <Col xs={5}>
          <Block>
            Col
            <br />
            xs=5
          </Block>
        </Col>
        <Col xs={10}>
          <Block>
            Col
            <br />
            xs=10
          </Block>
        </Col>
      </Row>
      <Typo variant="section">ROW options</Typo>
      <Typo variant="p">vertical, spacing=32</Typo>
      <Typo variant="p" color="primary">
        Row
      </Typo>
      <Row vertical spacing={32} override={{ row: classes.row }}>
        <Col xs={5}>
          <Block>
            Col
            <br />
            xs=5
          </Block>
        </Col>
        <Col xs={5}>
          <Block>
            Col
            <br />
            xs=5
          </Block>
        </Col>
        <Col xs={10}>
          <Block>
            Col
            <br />
            xs=10
          </Block>
        </Col>
      </Row>
      <Typo variant="section">ROW options</Typo>
      <Typo variant="p">vertical, spacing=16, root=0, parts=3</Typo>
      <Typo variant="p" color="primary">
        Row
      </Typo>
      <Row
        vertical
        spacing={16}
        root={0}
        parts={3}
        override={{ row: classes.row }}
      >
        <Col xs={3} md={1}>
          <Block>
            Col
            <br />
            xs=3, md=1
          </Block>
        </Col>
        <Col xs={3} md={1}>
          <Block>
            Col
            <br />
            xs=3, md=1
          </Block>
        </Col>
        <Col xs={3} md={1}>
          <Block>
            Col
            <br />
            xs=3, md=1
          </Block>
        </Col>
      </Row>
      <Typo variant="section">ROW options</Typo>
      <Typo variant="p">spacing=16, root=0</Typo>
      <Typo variant="p" color="primary">
        Row
      </Typo>
      <Row spacing={16} root={0} override={{ row: classes.row }}>
        <Col xs={5}>
          <Block>
            Col
            <br />
            xs=5
          </Block>
        </Col>
        <Col xs={5}>
          <Block>
            Col
            <br />
            xs=5
          </Block>
        </Col>
        <Col>
          <Block>Col</Block>
        </Col>
      </Row>
      <Typo variant="section">ROW options</Typo>
      <Typo variant="p">
        vertical, spacing=16, root=0, verticalAlign="bottom"
      </Typo>
      <Typo variant="p" color="primary">
        Row
      </Typo>
      <Row
        vertical
        spacing={16}
        root={0}
        verticalAlign="bottom"
        override={{ row: classes.row }}
      >
        <Col xs={10 / 3}>
          <Block>
            Col
            <br />
            xs=10 / 3
            <br />
            <br />
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
            et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
            accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </Block>
        </Col>
        <Col stretch xs={10 / 3}>
          <Block>
            Col
            <br />
            stretch
            <br />
            xs=10 / 3
          </Block>
        </Col>
        <Col xs={10 / 3}>
          <Block>
            Col
            <br />
            xs=10 / 3
          </Block>
        </Col>
      </Row>
      <Typo variant="section">ROW options</Typo>
      <Typo variant="p">
        vertical, spacing=16, root=0, horizontalAlign="right"
      </Typo>
      <Typo variant="p" color="primary">
        Row
      </Typo>
      <Row
        vertical
        spacing={16}
        root={0}
        horizontalAlign="right"
        override={{ row: classes.row }}
      >
        <Col xs={10 / 3}>
          <Block>
            Col
            <br />
            xs=10 / 3
          </Block>
        </Col>
        <Col xs={10 / 3}>
          <Block>
            Col
            <br />
            xs=10 / 3
          </Block>
        </Col>
      </Row>
    </>
  );
}
