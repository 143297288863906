import React, { useState } from "react";
import {
  Button,
  useSession,
  StateHandler,
  Typo,
  Row,
  Container,
  Block,
  SingelPageAuthLayout,
} from "@maxeb/admin-ui";
import { IDP } from "@datenbanker/idp-sdk";
import CodeViewer from "../CodeViewer";

export default function ExampleSingelPageAuth() {
  const [session, setSession] = useSession(null);
  const [status, setStatus] = useState<"ok" | "pending" | "error">("ok");

  if (session === null)
    return (
      <SingelPageAuthLayout session={session} setSession={setSession}>
        <Row spacing={16} vertical>
          Your Content goes here
          <Typo variant="a" to="/">
            Back
          </Typo>
        </Row>
      </SingelPageAuthLayout>
    );

  const json = JSON.stringify(session).replace(/,/g, ",\n  ");
  const code = `{\n  ${json.substring(1, json.length - 1)}\n}`;
  return (
    <div style={{ marginTop: "50px" }}>
      <Container>
        <StateHandler state={status}>
          <Block>
            <Row spacing={16} vertical root={0} horizontalAlign="right">
              <Typo variant="h3" color="primary" margin="none">
                Session
              </Typo>

              <CodeViewer language="typescript">{code}</CodeViewer>
              <Button
                xs="120px"
                danger
                onClick={async () => {
                  setStatus("pending");
                  await IDP.logout();
                  setStatus("ok");
                  setSession(null);
                }}
              >
                Logout
              </Button>
            </Row>
          </Block>
        </StateHandler>
      </Container>
    </div>
  );
}
