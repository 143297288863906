import React, { useState } from "react";
import { Select, Form } from "@maxeb/admin-ui";

export default function SelectInputExample() {
  const [value, setValue] = useState<string | undefined>();
  return (
    <Form>
      <Select
        label="Select"
        value={value}
        options={[
          { label: "Option 1", value: "option1" },
          { label: "Option 2", value: "option2" },
          { label: "Option 3", value: "option3" },
          { label: "Option 4", value: "option4" },
          { label: "Option 5", value: "option5" },
        ]}
        onChange={(value) => {
          if (typeof value !== "number") setValue(value);
        }}
      />
    </Form>
  );
}
