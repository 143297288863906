import React from "react";
import "./App.css";
import {
  I18nProvider,
  AdminLayout,
  Routes,
  Route,
  useRouter,
} from "@maxeb/admin-ui";

import Navigation from "./components/Navigation";
import Forms from "./components/forms/ExampleForms";
import ExampleLists from "./components/lists/ExampleLists";
import Flex from "./components/flex/Example";
import Installation from "./components/Installation";
import Initiallisation from "./components/Initialisation";
import Overview from "./components/Overview";
import AdminManagedLayoutExample from "./components/adminPanel/AdminManagedLayoutExample";
import Customization from "./components/customization";
import RowDoc from "./components/flex/Row";
import ColDoc from "./components/flex/Col";
import FormDoc from "./components/forms/Form";
import TextInputDoc from "./components/forms/TextInput";
import NumberInputDoc from "./components/forms/NumberInput";
import DateInputDoc from "./components/forms/DateInput";
import SelectDoc from "./components/forms/Select";
import RadioDoc from "./components/forms/Radio";
import RadioItemDoc from "./components/forms/RadioItem";
import CheckboxDoc from "./components/forms/CheckBox";
import UploadAreaDoc from "./components/forms/UploadArea";
import Button from "./components/forms/Button";
import ListDoc from "./components/lists/List";
import ListItemDoc from "./components/lists/ListItem";
import ExampleSidebarLists from "./components/sidebar/ExampleSidebarLists";
import SidebarSection from "./components/sidebar/Section";
import SidebarList from "./components/sidebar/List";
import SidebarListLink from "./components/sidebar/ListLink";
import Sidebar from "./components/sidebar/Sidebar";
import AdminLayoutDoc from "./components/adminPanel/AdminLayout";
import AdminManagedLayout from "./components/adminPanel/AdminManagedLayout";
import Authentication from "./components/authentication/Authentication";
import AuthenticationExample from "./components/authentication/Example";
import I18n from "./components/I18n";
import ExampleStateHandler from "./components/stateHandler/Example";
import StateHandler from "./components/stateHandler/StateHandler";
import ExampleSingelPageAuth from "./components/singelPageAuth/Example";
import SingelPageAuthLayout from "./components/singelPageAuth/SingelPageAuthLayout";
import ImageExample from "./components/image/Example";

function App() {
  const [router] = useRouter();

  const layoutToLoad = router.path.startsWith("/layouts/example/adminpanel")
    ? "adminpanel"
    : router.path.startsWith("/layouts/example/singelpageauth")
    ? "signon"
    : router.path.startsWith("/layouts/example/store")
    ? "store"
    : "default";

  return (
    <div className="App">
      <I18nProvider
        language="de"
        pathMap={{ de: "/i18n/de.json", en: "/i18n/en.json" }}
        pending={<div>Pending</div>}
        error={<div>Error</div>}
      >
        {layoutToLoad === "adminpanel" && <AdminManagedLayoutExample />}
        {layoutToLoad === "signon" && <ExampleSingelPageAuth />}
        {layoutToLoad === "default" && (
          <AdminLayout sidebar={<Navigation />}>
            <Routes>
              <Route path="/installation">
                <Installation />
              </Route>
              <Route path="/initialisation">
                <Initiallisation />
              </Route>
              <Route path="/customization">
                <Customization />
              </Route>
              <Route path="/overview">
                <Overview />
              </Route>
              <Route path="/layouts/adminpanel/adminlayout">
                <AdminLayoutDoc />
              </Route>
              <Route path="/layouts/adminpanel/adminmanagedlayout">
                <AdminManagedLayout />
              </Route>
              <Route path="/layouts/singelpageauth/singelpageauthlayout">
                <SingelPageAuthLayout />
              </Route>
              <Route path="/flex/example">
                <Flex />
              </Route>
              <Route path="/flex/row">
                <RowDoc />
              </Route>
              <Route path="/flex/col">
                <ColDoc />
              </Route>
              <Route path="/lists/example">
                <ExampleLists />
              </Route>
              <Route path="/lists/list">
                <ListDoc />
              </Route>
              <Route path="/lists/listitem">
                <ListItemDoc />
              </Route>
              <Route path="/forms/example">
                <Forms />
              </Route>
              <Route path="/forms/form">
                <FormDoc />
              </Route>
              <Route path="/forms/textinput">
                <TextInputDoc />
              </Route>
              <Route path="/forms/dateinput">
                <DateInputDoc />
              </Route>
              <Route path="/forms/numberinput">
                <NumberInputDoc />
              </Route>
              <Route path="/forms/select">
                <SelectDoc />
              </Route>
              <Route path="/forms/radio">
                <RadioDoc />
              </Route>
              <Route path="/forms/radioitem">
                <RadioItemDoc />
              </Route>
              <Route path="/forms/checkbox">
                <CheckboxDoc />
              </Route>
              <Route path="/forms/uploadarea">
                <UploadAreaDoc />
              </Route>
              <Route path="/forms/button">
                <Button />
              </Route>
              <Route path="/sidebar/example">
                <ExampleSidebarLists />
              </Route>
              <Route path="/sidebar/sidebar">
                <Sidebar />
              </Route>
              <Route path="/sidebar/section">
                <SidebarSection />
              </Route>
              <Route path="/sidebar/list">
                <SidebarList />
              </Route>
              <Route path="/sidebar/listlink">
                <SidebarListLink />
              </Route>
              <Route path="/i18n">
                <I18n />
              </Route>
              <Route path="/authentication/authenticator">
                <Authentication />
              </Route>
              <Route path="/authentication/example">
                <AuthenticationExample />
              </Route>
              <Route path="/statehandler/example">
                <ExampleStateHandler />
              </Route>
              <Route path="/statehandler/statehandler">
                <StateHandler />
              </Route>
              <Route path="/image/example">
                <ImageExample />
              </Route>
            </Routes>
          </AdminLayout>
        )}
      </I18nProvider>
    </div>
  );
}

export default App;
