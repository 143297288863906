import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";
import AdminLayoutExample from "./AdminLayoutExample";

export default function AdminLayoutDoc() {
  return (
    <>
      <Typo variant="h1">AdminLayout</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description={<>A layout for admin or controlling dashboard.</>}
            properties={[
              {
                name: "topbar",
                default: "undefined",
                type: ["React.ReactNode", "undefined"],
                description: "Elements you want to see in top white bar.",
              },
              {
                name: "sidebar",
                default: "undefined",
                type: ["React.ReactNode", "undefined"],
                description: "Content in sidebar.",
              },
              {
                name: "children",
                default: "undefined",
                type: ["React.ReactNode", "undefined"],
                description: "Page content.",
              },
            ]}
          />
          <Example
            label="Example"
            example={<AdminLayoutExample />}
            language="typescript"
            code={
              'import React from "react";\nimport { AdminLayout } from "@maxeb/admin-ui";\nimport SidebarExample from "../sidebar/SidebarExample";\n\nexport default function AdminLayoutExample() {\n  return (\n    <div style={{ border: "1px solid" }}>\n      <AdminLayout topbar="topbar-content" sidebar={<SidebarExample />}>\n        Page-Content\n      </AdminLayout>\n    </div>\n  );\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
