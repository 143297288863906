import React from "react";
import { Col, Row, Typo } from "@maxeb/admin-ui";
import DocumentationComponent from "../DocumentationComponent";
import RowCol from "../examples/RowCol";
import Example from "../DocumentationExample";

export interface IFlex {}

export default function ColDoc(props: IFlex) {
  return (
    <>
      <Typo variant="h1">Col</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <DocumentationComponent
            description="This component together with the Row component is used as an easy way to allign elements in an row and column arragnement. These components make use of the css flex properties.The Col component allows you to define diffrent column widths for diffrent breakpoints."
            properties={[
              {
                name: "xs",
                default: "parts value",
                type: [
                  "number",
                  '"rest"',
                  '"auto"',
                  '"hidden"',
                  '"hidden-down"',
                  '"hidden-up"',
                ],
                description:
                  'Value for width in parts for xs breakpoint. You can use "rest" as value to fill the rest of row or "auto" to to grow with the content in the column.',
              },
              {
                name: "sm",
                default: "undefined",
                type: [
                  "number",
                  '"rest"',
                  '"auto"',
                  '"hidden"',
                  '"hidden-down"',
                  '"hidden-up"',
                ],
                description:
                  'Value for width in parts for sm breakpoint. You can use "rest" as value to fill the rest of row or "auto" to to grow with the content in the column. If not defined value for next defined lower breakpoint is used.',
              },
              {
                name: "md",
                default: "undefined",
                type: [
                  "number",
                  '"rest"',
                  '"auto"',
                  '"hidden"',
                  '"hidden-down"',
                  '"hidden-up"',
                ],
                description:
                  'Value for width in parts for md breakpoint. You can use "rest" as value to fill the rest of row or "auto" to to grow with the content in the column. If not defined value for next defined lower breakpoint is used.',
              },
              {
                name: "lg",
                default: "undefined",
                type: [
                  "number",
                  '"rest"',
                  '"auto"',
                  '"hidden"',
                  '"hidden-down"',
                  '"hidden-up"',
                ],
                description:
                  'Value for width in parts for lg breakpoint. You can use "rest" as value to fill the rest of row or "auto" to to grow with the content in the column. If not defined value for next defined lower breakpoint is used.',
              },
              {
                name: "xl",
                default: "undefined",
                type: [
                  "number",
                  '"rest"',
                  '"auto"',
                  '"hidden"',
                  '"hidden-down"',
                  '"hidden-up"',
                ],
                description:
                  'Value for width in parts for xl breakpoint. You can use "rest" as value to fill the rest of row or "auto" to to grow with the content in the column. If not defined value for next defined lower breakpoint is used.',
              },
              {
                name: "children",
                default: "undefined",
                type: ["React.ReactNode"],
                description: "Elements shown in column.",
              },
              {
                name: "stretch",
                default: "false",
                type: ["boolean"],
                description: "Stretches to size of parent row.",
              },
              {
                name: "spacing",
                default: "row spacing",
                type: ["number"],
                description:
                  "Dont use it. It will be forwarded from row. Will soon be hidden behinde forward interface.",
              },
              {
                name: "vertical",
                default: "row vertical",
                type: ["boolean"],
                description:
                  "Dont use it. It will be forwarded from row. Will soon be hidden behinde forward interface.",
              },
              {
                name: "parts",
                default: "row parts",
                type: ["number"],
                description:
                  "Dont use it. It will be forwarded from row. Will soon be hidden behinde forward interface.",
              },
              {
                name: "override",
                default: "top",
                type: ["{root?: string; row?: string;};"],
                description:
                  "Override property for css class injections. See customization section for more info.",
              },
            ]}
            example={<RowCol />}
            language="typescript"
            exampleCode={
              'import React from "react";\nimport { useClasses, ITheme, Block, Col, Row, Typo } from "@temeium/uui";\n\nconst styles = (theme: ITheme) => ({\n  border: {\n    border: "1px solid " + theme.palette.get("primary"),\n  },\n});\n\nexport default function RowCol() {\n  const classes = useClasses(styles, props);\n  return (\n    <>\n      <Typo variant="p" color="primary">\n        Row\n      </Typo>\n      <Row vertical spacing={16} root={8} override={{ row: classes.border }}>\n        <Col md={5} lg={10 / 3}>\n          <Block>\n            Col\n            <br />\n            md=5\n            <br />\n            lg=10 / 3\n          </Block>\n        </Col>\n        <Col md={5} lg={10 / 3}>\n          <Block>\n            Col\n            <br />\n            md=5\n            <br />\n            lg=10 / 3\n          </Block>\n        </Col>\n        <Col md={5} lg={10 / 3}>\n          <Block>\n            Col\n            <br />\n            md=5\n            <br />\n            lg=10 / 3\n          </Block>\n        </Col>\n      </Row>\n    </>\n  );\n}'
            }
          />
          <Example
            label="Example"
            example={<RowCol />}
            language="typescript"
            code={
              'import React from "react";\nimport { useClasses, ITheme, Block, Col, Row, Typo } from "@temeium/uui";\n\nconst styles = (theme: ITheme) => ({\n  border: {\n    border: "1px solid " + theme.palette.get("primary"),\n  },\n});\n\nexport default function RowCol() {\n  const classes = useClasses(styles, props);\n  return (\n    <>\n      <Typo variant="p" color="primary">\n        Row\n      </Typo>\n      <Row vertical spacing={16} root={8} override={{ row: classes.border }}>\n        <Col md={5} lg={10 / 3}>\n          <Block>\n            Col\n            <br />\n            md=5\n            <br />\n            lg=10 / 3\n          </Block>\n        </Col>\n        <Col md={5} lg={10 / 3}>\n          <Block>\n            Col\n            <br />\n            md=5\n            <br />\n            lg=10 / 3\n          </Block>\n        </Col>\n        <Col md={5} lg={10 / 3}>\n          <Block>\n            Col\n            <br />\n            md=5\n            <br />\n            lg=10 / 3\n          </Block>\n        </Col>\n      </Row>\n    </>\n  );\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
