import React from "react";
import { faBook } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Block, Col, List, ListItem, Row, Typo } from "@maxeb/admin-ui";

export default function ExampleLists() {
  return (
    <>
      <Typo variant="h1">Lists</Typo>
      <Row root={0} spacing={16}>
        <Col md={10 / 3}>
          <Block>
            <List icon={<FontAwesomeIcon icon={faBook} />} label="Liste">
              <ListItem>Element 1</ListItem>
              <ListItem>Element 2</ListItem>
              <ListItem>Element 3</ListItem>
            </List>
          </Block>
        </Col>
        <Col md={10 / 3}>
          <Block>
            <List
              icon={<FontAwesomeIcon icon={faBook} />}
              label="Liste"
              collapseAble
              open
            >
              <ListItem>Element 1</ListItem>
              <ListItem>Element 2</ListItem>
              <ListItem>Element 3</ListItem>
            </List>
          </Block>
        </Col>
        <Col md={10 / 3}>
          <Block>
            <List label="some" icon={<FontAwesomeIcon icon={faBook} />}>
              <ListItem>Element 1</ListItem>
              <ListItem>Element 2</ListItem>
              <ListItem>Element 3</ListItem>
            </List>
          </Block>
        </Col>
      </Row>
    </>
  );
}
