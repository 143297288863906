import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";
import RadioExample from "./RadioExample";
import RadioInfoErrorExample from "./RadioInfoErrorExample";

export default function RadioDoc() {
  return (
    <>
      <Typo variant="h1">RadioItem</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description={
              <>
                Child component for radio{" "}
                <Typo variant="a" to="/froms/radio">
                  Radio component
                </Typo>
                . This component represents the options for your radio input.
                RadioItem extends the flex Col component, so you dont need to
                wrap it in a Col and can directly use it as a child of a Radio.
              </>
            }
            extends={[{ label: "Col", to: "/flex/col" }]}
            properties={[
              {
                name: "label",
                default: "string",
                type: ["string", "undefined"],
                description: "Label of radioitem option.",
              },
              {
                name: "value",
                default: "undefined",
                type: ["string", "undefined"],
                description:
                  "Value which will be given to radio element if selected.",
              },

              {
                name: "onClick",
                default: "() => {}",
                type: ["(value?: string) => void"],
                description:
                  "Function triggers when option is clicked, will be injected by parent Radio component.",
              },
              {
                name: "hasError",
                default: "undefined",
                type: ["boolean", "undefined"],
                description:
                  "Triggers error highlighting will be injected by parent Radio component.",
              },
            ]}
          />
          <Example
            label="Example inline"
            example={<RadioExample />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { Radio, Form, RadioItem } from "@maxeb/admin-ui";\n\nexport default function RadioExample() {\n  const [value, setValue] = useState<string | undefined>();\n\n  return (\n    <Form spacing={16} root={0}>\n      <Radio label="Select" inline value={value} onChange={setValue}>\n        <RadioItem sm={5} lg={10 / 4} label="Option 1" value="option1" />\n        <RadioItem sm={5} lg={10 / 4} label="Option 2" value="option2" />\n        <RadioItem sm={5} lg={10 / 4} label="Option 3" value="option3" />\n        <RadioItem sm={5} lg={10 / 4} label="Option 4" value="option4" />\n      </Radio>\n    </Form>\n  );\n}'
            }
          />
          <Example
            label="Example search"
            example={<RadioInfoErrorExample />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { Radio, RadioItem, Form, Button } from "@maxeb/admin-ui";\n\nexport default function RadioInfoErrorExample() {\n  const [value, setValue] = useState<string | undefined>();\n  const [error, setError] = useState<string | undefined>();\n\n  return (\n    <Form spacing={16} root={0}>\n      <Radio\n        sm="rest"\n        label="Select"\n        info="A info for your radio boxes."\n        error={error}\n        value={value}\n        onChange={setValue}\n      >\n        <RadioItem sm={5} lg={10 / 4} label="Option 1" value="option1" />\n        <RadioItem sm={5} lg={10 / 4} label="Option 2" value="option2" />\n        <RadioItem sm={5} lg={10 / 4} label="Option 3" value="option3" />\n      </Radio>\n      <Button\n        xs="120px"\n        alignFloating\n        danger\n        onClick={() => {\n          if (!error) setError("A test error for your input!");\n          else setError(undefined);\n        }}\n      >\n        Error\n      </Button>\n    </Form>\n  );\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
