import React from "react";
import { Table } from "@maxeb/admin-ui";

const columns = [
  { label: "Name", field: "name" },
  { label: "Default", field: "value" },
  { label: "Used for i.e.", field: "description" },
];

const data = [
  {
    name: "primary",
    value: "#5c90d2",
    description: "secions labels in sidebar, links, buttons",
  },
  {
    name: "secondary",
    value: "#2c689c",
    description: "mouseover color for elements colored with primary",
  },
  {
    name: "tertiary",
    value: "#212a37",
    description: "background for sidebar",
  },
  {
    name: "danger",
    value: "#b7433f",
    description: "error message and buttons",
  },
  {
    name: "warning",
    value: "#e8db05",
    description: "warning messages and buttons",
  },
  {
    name: "default",
    value: "#ececec",
    description: "default color for buttons",
  },
  {
    name: "grey",
    value: "#e0e0e0",
    description: "inactive sidebar elements, table borders",
  },
  {
    name: "font",
    value: "#3e3e3e",
    description: "default color for text",
  },
  {
    name: "background",
    value: "#ececec",

    description: "body background color",
  },
  {
    name: "formLabel",
    value: "#797979",
    description: "label of input fields",
  },
  {
    name: "formBackground",
    value: "#ececec",
    description: "background of input fields",
  },
  {
    name: "formBackgroundActive",
    value: "#e0e0e0",
    description: "backgorund color for focused input fields",
  },
  {
    name: "section",
    value: "#717171",
    description: "secions labels in sidebar, links, buttons",
  },
];

export default function ColorTable() {
  return <Table columns={columns} data={data} />;
}
