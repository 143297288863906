import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";
import UploadAreaExample from "./UploadAreaExample";
import UploadAreaInfoError from "./UploadAreaInfoErrorExample";

export default function UploadAreaDoc() {
  return (
    <>
      <Typo variant="h1">UploadArea</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description={
              <>
                A upload area for your forms. It should be wrapped in a{" "}
                <Typo variant="a" to="/froms/form">
                  Form component
                </Typo>
                . It will parse the file to upload in base64 and will provide it
                to you through on change in format of{" "}
                <i style={{ whiteSpace: "nowrap" }}>
                  {"<NAME>"},{"<BASE_64_CONTENT>"}
                </i>{" "}
                as string. UploadArea extends the flex Col component, so you
                dont need to wrap it in a Col and can directly use it as a child
                of a Row or a Form component.
              </>
            }
            extends={[{ label: "Col", to: "/flex/col" }]}
            properties={[
              {
                name: "label",
                default: "undefined",
                type: ["string", "undefined"],
                description: "Adds a string as label in uploadArea.",
              },
              {
                name: "value",
                default: "undefined",
                type: ["string", "undefined"],
                description: "Base64 value of file to upload.",
              },
              {
                name: "onChange",
                default: "() => void",
                type: ["(value: string) => void", "undefined"],
                description:
                  "Function will be triggered if user adds file to upload and gives base64 string of file.",
              },
              {
                name: "pendingElement",
                default: "undefined",
                type: ["React.ReactNode", "undefined"],
                description: "Element shown while file is parsed to base64.",
              },
              {
                name: "height",
                required: true,
                type: ["string", "number"],
                description: "Height of the area as css property.",
              },
              {
                name: "inline",
                default: "undefined",
                type: ["boolean", "undefined"],
                description:
                  "Removes margin from top of area to align with other inline elements.",
              },
              {
                name: "info",
                default: "undefined",
                type: ["string", "undefined"],
                description: "Info shown under the upload area.",
              },
              {
                name: "error",
                default: "undefined",
                type: ["string", "undefined"],
                description:
                  "If set shows string under upload area and sets error highlighting.",
              },
              {
                name: "override",
                default: "undefined",
                type: [
                  {
                    "input?": "string",
                    "col?": "string",
                  },
                  "undefined",
                ],
                description:
                  "Override property for css class injections. See customization section for more info.",
              },
            ]}
          />
          <Example
            label="Example"
            example={<UploadAreaExample />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { Form, UploadArea } from "@maxeb/admin-ui";\n\nexport default function CheckboxExample() {\n  const [value, setValue] = useState<string | undefined>();\n\n  return (\n    <Form spacing={16} root={0}>\n      <UploadArea\n        height="150px"\n        value={value}\n        onChange={(file) => {\n          console.log(file);\n          setValue(file);\n        }}\n      >\n        Upload file!\n      </UploadArea>\n    </Form>\n  );\n}'
            }
          />
          <Example
            label="Example inline & error"
            example={<UploadAreaInfoError />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { Form, Button, UploadArea } from "@maxeb/admin-ui";\n\nexport default function UploadAreaInfoError() {\n  const [value, setValue] = useState<string | undefined>();\n  const [error, setError] = useState<string | undefined>();\n\n  return (\n    <Form vertical spacing={16} root={0} horizontalAlign="right">\n      <UploadArea\n        sm="rest"\n        height="150px"\n        inline\n        value={value}\n        onChange={setValue}\n        info="A info block you can set to inform."\n        error={error}\n      >\n        Upload File\n      </UploadArea>\n      <Button\n        xs="120px"\n        danger\n        onClick={() => {\n          if (!error) setError("A test error for your input!");\n          else setError(undefined);\n        }}\n      >\n        Error\n      </Button>\n    </Form>\n  );\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
