import React, { useState } from "react";
import { TextInput, Form } from "@maxeb/admin-ui";

export default function TextExample() {
  const [value, setValue] = useState<string | undefined>();
  return (
    <Form>
      <TextInput label="TextInput" value={value} onChange={setValue} />
    </Form>
  );
}
