import React from "react";
import { Typo, Row, Col, Block } from "@maxeb/admin-ui";
import CodeViewer from "./CodeViewer";
import I18nChangeLanguage from "./examples/I18nChangeLanguage";
import I18nGetTranslations from "./examples/I18nGetTranslations";

export interface ICustomization {}

export default function Customization(props: ICustomization) {
  return (
    <>
      <Typo variant="h1">I18N</Typo>
      <Row root={0} spacing={16} vertical>
        <Col lg={7}>
          <Typo variant="section">Translation Files</Typo>
          <Block>
            <Typo variant="h3" color="primary" margin="none">
              Description
            </Typo>
            <Typo variant="p">
              A transflation file is holding your key {"=>"} text mapping orderd
              in groups for one languag in Json format.
              <br />
              Translation files are loaded asyncronously if the language is
              needed.
            </Typo>
            <Typo variant="h3" color="primary">
              Create translation file.
            </Typo>
            <Typo variant="p">
              Translation files need to be located within your ./public folder.
              I personally like to create an i18n subfolder containing my
              translation files. However as long as it is in ./public it is up
              to you.
              <br />
              For this example i save the following files in ./public/i18n.
              <br />
            </Typo>
            <Typo variant="p">
              <b>./public/i18n/de.json</b>
            </Typo>
            <CodeViewer language="typescript">
              {
                '{\n    //group\n    "forms": {\n        //key: value\n        "inline": "In Line",\n        "floating": "Fließend",\n        "open": "Offen",\n        "example": "Beispiel"\n    },\n    "example": {\n        "helloWorldTranslation": "Hallo Welt ich bin eine Übersetzung."\n    }\n}'
              }
            </CodeViewer>
            <Typo variant="p">
              <b>./public/i18n/en.json</b>
            </Typo>
            <CodeViewer language="typescript">
              {
                '{\n    //group\n    "forms": {\n        //key: value\n        "inline": "Inline",\n        "floating": "Floating",\n        "open": "Open",\n        "example": "Example"\n    },\n    "example": {\n        "helloWorldTranslation": "Hello world I am a translation."\n    }\n}'
              }
            </CodeViewer>
          </Block>
          <Typo variant="section">Initialisation</Typo>
          <Block>
            <Typo variant="h3" color="primary" margin="none">
              Description
            </Typo>
            <Typo variant="p">
              Before connecting your react components to i18n you need to
              initialise the lib and tell it your language {"=>"}{" "}
              translation-file mapping.
            </Typo>
            <Typo variant="p">
              Best place to initialise i18n is in your App component. You don't
              have to initialise it there but you should note that hooks or
              containers to access your translation are only working in
              components which are children of the I18nProvider. So you propably
              want it to be quite high up your component tree.
            </Typo>
            <Typo variant="p">
              In your <b>./App.tsx</b>.
            </Typo>
            <CodeViewer language="typescript">
              {
                'import React from "react";\nimport "./App.css";\nimport {\n  I18nProvider,\n} from "@maxeb/admin-ui";\n\nfunction App() {\n  return (\n    <div className="App">\n      <I18nProvider\n        language="de"  //defaul language\n        pathMap={{ de: "/i18n/de.json", en: "/i18n/en.json" }}  //language file mapping\n        pending={<div>Pending</div>}  //element shown while loading translation\n        error={<div>Error</div>}  //element shown if loading error\n      >\n       {/* Your components with access to i18n go here*/}\n      </I18nProvider>\n    </div>\n  );\n}\nexport default App;'
              }
            </CodeViewer>
            <Typo variant="p">
              For more information on I18nProvider component look at its{" "}
              <Typo variant="a" to="/i18n/i18nprovider">
                documentation
              </Typo>
            </Typo>
          </Block>
          <Typo variant="section">Connecting</Typo>
          <Block>
            <Typo variant="h3" color="primary" margin="none">
              Description
            </Typo>
            <Typo variant="p">
              Since now we are setup lets connect to our translations and use
              them in our components.
            </Typo>

            <Typo variant="h3" color="primary">
              Example
            </Typo>
            <I18nGetTranslations />
            <Typo variant="h3" color="primary">
              Code
            </Typo>
            <CodeViewer language="typescript">
              {
                'import React from "react";\nimport { useI18n, Typo } from "@maxeb/admin-ui";\nexport default function I18nGetTranslation() {\n  //create hook and set tanslation group\n  const i18n = useI18n("example");\n  return (\n    <Typo variant="p">\n      {\n        //get translation value as string by its key\n        i18n.get("helloWorldTranslation")\n      }\n    </Typo>\n  );\n}'
              }
            </CodeViewer>
            <Typo variant="p">
              For more information on useI18n hook look at its{" "}
              <Typo variant="a" to="/i18n/useI18n">
                documentation
              </Typo>
            </Typo>
          </Block>
          <Typo variant="section">Change Language</Typo>
          <Block>
            <Typo variant="h3" color="primary" margin="none">
              Description
            </Typo>
            <Typo variant="p">
              Of course we want to change the language, because otherwise all
              the above would not make much sense.
              <br />
              Example bewlow will reload content.
            </Typo>
            <Typo variant="h3" color="primary">
              Example
            </Typo>
            <I18nChangeLanguage />
            <Typo variant="h3" color="primary">
              Code
            </Typo>
            <CodeViewer language="typescript">
              {
                'import React from "react";\nimport { Row, Col, useI18n, Button } from "@maxeb/admin-ui";\n\nexport default function I18nChangeLanguage() {\n  const i18n = useI18n("example");\n\n  return (\n    <Row>\n      <Col xs="rest">\n        Current Languag: <b>{i18n.language}</b>\n      </Col>\n      <Button\n        xs="100px"\n        primary\n        onClick={() => {\n          i18n.load(i18n.language === "en" ? "de" : "en");\n        }}\n      >\n        Change\n      </Button>\n    </Row>\n  );\n}'
              }
            </CodeViewer>

            <Typo variant="p">
              For more information on useI18n hook look at its{" "}
              <Typo variant="a" to="/i18n/useI18n">
                documentation
              </Typo>
            </Typo>
          </Block>
        </Col>
      </Row>
    </>
  );
}
