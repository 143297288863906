import React from "react";
import { Typo, Row, Col, Block } from "@maxeb/admin-ui";
import CodeViewer from "../CodeViewer";
import DocumentationBlock from "../DocumentationBlock";
import MyBlueDivCC from "../examples/MyBlueDivCC";
import MyBlueDivFC from "../examples/MyBlueDivFC";
import MyResponsiveColorChanger from "../examples/MyResponsiveColorChanger";
import BreakpointTable from "./BreakpointTable";
import ColorTable from "./ColorTable";

export interface ICustomization {}

export default function Customization(props: ICustomization) {
  return (
    <>
      <Typo variant="h1">Customization</Typo>
      <Row root={0} spacing={16} vertical>
        <Col lg={7}>
          <Typo variant="section">Colors</Typo>
          <Block>
            <Typo variant="h3" color="primary" margin="none">
              Description
            </Typo>
            <Typo variant="p">
              This theme has a global color palette implemented where all
              components consume theire colors from. You can change the values
              of the colors when you initialise the theme in your index.tsx.
            </Typo>
            <Typo variant="h3" color="primary">
              Default colors in palette
            </Typo>
            <ColorTable />
            <Typo variant="h3" color="primary">
              Overwrite and add colors
            </Typo>
            <Typo variant="p">
              Global color changes need to be set at initallisation of the theme
              in your index.tsx.
            </Typo>
            <CodeViewer language="typescript">
              {
                'const options = {\n  palette: {\n    primary: "red",  //color primary will be overwritten\n    myColor: "blue",  //color myColor will be added to the palette\n  },\n};\ninit(options);'
              }
            </CodeViewer>
          </Block>
          <Typo variant="section">Brakepoints</Typo>
          <Block>
            <Typo variant="h3" color="primary" margin="none">
              Description
            </Typo>
            <Typo variant="p">
              This theme uses breakpoints youn can leverage to show the best
              version of your site on every display size. Also some components
              and layouts will align them diffrent and change theire behavior on
              certain breakpoints.
            </Typo>
            <Typo variant="h3" color="primary">
              Default breakpoints
            </Typo>
            <BreakpointTable />
            <Typo variant="h3" color="primary">
              Overwrite breakpoints
            </Typo>
            <Typo variant="p">
              Breakpoint changes are also global and therefore need to be set at
              initialisation in your index.tsx.
            </Typo>
            <CodeViewer language="typescript">
              {
                'const options = {\n  breakpoints: {\n    sm: "600px",  //breakpoint sm\'s lower limit is now 600px\n  },\n};\ninit(options);'
              }
            </CodeViewer>
          </Block>
          <Typo variant="section">JSS</Typo>
          <Block>
            <Typo variant="h3" color="primary" margin="none">
              Description
            </Typo>
            <Typo variant="p">
              Jss is a convinent way to write CSS in JS. If you have not heard
              about it yet, I highly encourage you to have a look at{" "}
              <Typo variant="a" to="https://cssinjs.org">
                JSS's Documentation
              </Typo>
              .<br />
              This theme comes with an wrapper for jss so you can access colors
              and breakpoints of the theme and use JSS to style your and thmes
              components.
              <br />
              Depending on your prefrence you can either use the hook version or
              the container version for integration.
            </Typo>
          </Block>
          <Typo variant="section">JSS-Hook</Typo>
          <DocumentationBlock
            language="typescript"
            description="This is a guide to integrate jss via a hook. Perfect for your function style components."
            steps={[
              {
                label:
                  "Import useClasses and ITheme interface from theme packages.",
                code: 'import { useClasses, ITheme } from "@maxeb/admin-ui";',
              },
              {
                label: "Create styles function",
                code: "const styles = (theme: ITheme) => ({});",
              },
              {
                label:
                  "Add classes and properties to your styles (for more infos on theme: ITheme variable scroll down to access theme values)",
                code: 'const styles = (theme: ITheme) => ({\n  myBlueDiv: {\n    backgroundColor: "blue",\n    color: "white",\n    padding: "32px 0px",\n    textAlign: "center",\n  },\n});',
              },
              {
                label: "Add hook to your component.",
                code: "export default function MyBlueDivFC(props: MyBlueDivFCProps) {\n  const classes = useClasses(styles, props);\n  return <div className={classes.myBlueDiv}>Hello I am a blue div.</div>;\n}",
              },
            ]}
            exampleDescrtiption="Complete example of hook integration."
            exampleCode={
              'import React from "react";\nimport { useClasses, ITheme } from "@temeium/uui";\n\ninterface MyBlueDivFCProps {}\n\nconst styles = (theme: ITheme) => ({\n  myBlueDiv: {\n    backgroundColor: "blue",\n    color: "white",\n    padding: "32px 0px",\n    textAlign: "center",\n  },\n});\n\nexport default function MyBlueDivFC(props: MyBlueDivFCProps) {\n  const classes = useClasses(styles, props);\n  return <div className={classes.myBlueDiv}>Hello I am a blue div.</div>;\n}'
            }
            example={<MyBlueDivFC />}
          />
          <Typo variant="section">JSS-Container</Typo>
          <DocumentationBlock
            language="typescript"
            description="This is a guide to integrate jss via a container. Perfect for your class style components."
            steps={[
              {
                label:
                  "Import withClasses, IWithClasses and ITheme interface from theme package.",
                code: 'import { withClasses, IWithClasses, ITheme } from "@temeium/uui";',
              },
              {
                label: "Create styles function",
                code: "const styles = (theme: ITheme) => ({});",
              },
              {
                label:
                  "Add classes and properties to your styles (for more infos on theme: ITheme variable scroll down to access theme values)",
                code: 'const styles = (theme: ITheme) => ({\n  myBlueDiv: {\n    backgroundColor: "blue",\n    color: "white",\n    padding: "32px 0px",\n    textAlign: "center",\n  },\n});',
              },
              {
                label:
                  'Wrap container around your component with "withClasses". If you are using typescript you need also to wrap interface "IWithClasses" around your propertie\'s interface.',
                code: "type PropsType = IWithClasses<MyBlueDivCCProps>;\n\nclass MyBlueDivCC extends React.Component<PropsType, {}> {\n  render() {\n    const { classes } = this.props;\n    return <div className={classes.myBlueDiv}>Hello I am a blue div.</div>;\n  }\n}\nexport default withClasses(styles, MyBlueDivCC);",
              },
            ]}
            exampleDescrtiption="Complete example of hook integration."
            exampleCode={
              'import React from "react";\nimport { withClasses, IWithClasses, ITheme } from "@temeium/uui";\n\ninterface MyBlueDivCCProps {}\n\nconst styles = (theme: ITheme) => ({\n  myBlueDiv: {\n    backgroundColor: "blue",\n    color: "white",\n    padding: "32px 0px",\n    textAlign: "center",\n  },\n});\n\n//wrap propertie\'s interface with injection propertie\'s interface\ntype PropsType = IWithClasses<MyBlueDivCCProps>;\n\nclass MyBlueDivCC extends React.Component<PropsType, {}> {\n  render() {\n    const { classes } = this.props;\n    return <div className={classes.myBlueDiv}>Hello I am a blue div.</div>;\n  }\n}\n//wrap component with container\nexport default withClasses(styles, MyBlueDivCC);'
            }
            example={<MyBlueDivCC />}
          />
          <Typo variant="section">Acces Theme values</Typo>
          <DocumentationBlock
            language="typescript"
            description={
              <>
                As you have already seen your styles function can be filled with
                jss. In this section we will take a look at how to get colors
                and breakpoints from theme.
                <br />
                Therefore we want to create a component that has
                <ul>
                  <li>the primary color from the breakpoint lg uppwards.</li>
                  <li>the danger color from sm upwards</li>
                  <li>and the success color inbetween.</li>
                </ul>
                <i>
                  I will use a hook for this example. However it works just like
                  in a container component.
                </i>
              </>
            }
            steps={[
              {
                label:
                  "Import useClasses and ITheme interface from theme package.",
                code: 'import { useClasses, ITheme } from "@temeium/uui";',
              },
              {
                label: "Create styles function",
                code: "const styles = (theme: ITheme) => ({});",
              },
              {
                label:
                  "We need to get the background color for our div from the theme.",
                code: 'const styles = (theme: ITheme) => ({\n  wrapper: {\n    padding: "32px",\n    color: "white",\n    backgroundColor: theme.palette.get("success"), //get color succss from theme\n  },\n});',
              },
              {
                label:
                  "Now we want to get our media queries for the screen sizes from the theme so we can add them to our wrapper class with the other background colors.",
                code: 'wrapper: {\n    padding: "32px",\n    color: "white",\n    backgroundColor: theme.palette.get("success"), //get color succss from theme\n    //get media query for display size sm down\n    [theme.breakpoints.down("sm")]: {\n      backgroundColor: theme.palette.get("danger"), //get color danger from theme\n    },\n    //get media query for display size lg up\n    [theme.breakpoints.up("lg")]: {\n      backgroundColor: theme.palette.get("primary"), //get color primary from theme\n    },\n  },',
              },
              {
                label: "Adding our hook to the component and we are finished.",
                code: "export default function MyResponsiveColorChanger() {\n  const classes = useClasses(styles);\n  return <div className={classes.wrapper}>Change Window size!</div>;\n}",
              },
            ]}
            exampleDescrtiption="Complete example of hook integration."
            exampleCode={
              'import React from "react";\nimport { useClasses, ITheme } from "@temeium/uui";\n\nconst styles = (theme: ITheme) => ({\n  wrapper: {\n    padding: "32px",\n    color: "white",\n    backgroundColor: theme.palette.get("success"), //get color succss from theme\n    //get media query for display size sm down\n    [theme.breakpoints.down("sm")]: {\n      backgroundColor: theme.palette.get("danger"), //get color danger from theme\n    },\n    //get media query for display size lg up\n    [theme.breakpoints.up("lg")]: {\n      backgroundColor: theme.palette.get("primary"), //get color primary from theme\n    },\n  },\n});\n\nexport default function MyResponsiveColorChanger() {\n  const classes = useClasses(styles);\n  return <div className={classes.wrapper}>Change Window size!</div>;\n}'
            }
            example={<MyResponsiveColorChanger />}
          />
        </Col>
      </Row>
    </>
  );
}
