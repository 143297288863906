"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDELETE = exports.createPATCH = exports.createPUT = exports.createGET = exports.createPOST = exports.getErrors = exports.isError = exports.isSuccess = exports.isErrorBody = exports.isSuccessBody = void 0;
function isSuccessBody(body) {
    return (typeof body !== null &&
        typeof body === "object" &&
        typeof body.status === "string" &&
        typeof body.data !== "undefined");
}
exports.isSuccessBody = isSuccessBody;
function isErrorBody(body) {
    return (typeof body !== null &&
        typeof body === "object" &&
        typeof body.error === "string" &&
        typeof body.message === "string" &&
        typeof body.errors !== null &&
        typeof body.errors === "object");
}
exports.isErrorBody = isErrorBody;
function isSuccess(response) {
    return (response.status >= 200 &&
        response.status < 300 &&
        isSuccessBody(response.body));
}
exports.isSuccess = isSuccess;
function isError(response) {
    return ((response.status < 200 || response.status >= 300) &&
        isErrorBody(response.body));
}
exports.isError = isError;
function request(url, method, apiKey, conditions, projection, limit, body) {
    return __awaiter(this, void 0, void 0, function* () {
        const encodedConditions = btoa(JSON.stringify(conditions));
        const encodedProjection = btoa(JSON.stringify(projection));
        const params = `?condition=${encodedConditions}&projection=${encodedProjection}&limit=${limit}`;
        const options = {
            method,
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
                "Content-Type": "application/json; charset=utf8",
                "x-api-key": apiKey,
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: method !== "GET" && method !== "DELETE" ? JSON.stringify(body) : null,
        };
        const path = method !== "POST" ? url + params : url;
        const response = yield fetch(path, options);
        return parseJsonResponse(response);
    });
}
function parseHeaders(response) {
    const headers = {};
    for (const [key, value] of response.headers.entries()) {
        headers[key] = value;
    }
    return headers;
}
function parseJsonResponse(response) {
    return __awaiter(this, void 0, void 0, function* () {
        const { status } = response;
        if (status === 200) {
            const body = yield response.json();
            if (!isSuccessBody(body))
                throw Error("Response does not match expected success scheme.");
            return { status, headers: parseHeaders(response), body };
        }
        const body = yield response.json();
        if (!isErrorBody(body))
            throw Error("Response does not match expected error scheme.");
        return { status, headers: parseHeaders(response), body };
    });
}
function getErrors(fieldMap, response) {
    const { body } = response;
    if (isSuccessBody(body))
        return {};
    if (isErrorBody(body)) {
        const errors = {};
        for (const key of Object.keys(body.errors)) {
            const error = body.errors[key];
            if (!fieldMap[key])
                throw Error("Undefined error " + key + ": " + error);
            const mappedKey = fieldMap[key];
            errors[mappedKey] = error;
        }
        return errors;
    }
    throw Error("connector::getErrors: body does not match expectations.");
}
exports.getErrors = getErrors;
function createPOST(url, apiKey) {
    return function (body) {
        return request(url, "POST", apiKey, {}, [], 1, body);
    };
}
exports.createPOST = createPOST;
function createGET(url, apiKey) {
    return function (condition, projection, limit) {
        return request(url, "GET", apiKey, condition, projection, limit, {});
    };
}
exports.createGET = createGET;
function createPUT(url, apiKey) {
    return function (condition, projection, body) {
        return request(url, "PUT", apiKey, condition, projection, 1, body);
    };
}
exports.createPUT = createPUT;
function createPATCH(url, apiKey) {
    return function (condition, projection, body) {
        return request(url, "PATCH", apiKey, condition, projection, 1, body);
    };
}
exports.createPATCH = createPATCH;
function createDELETE(url, apiKey) {
    return function (condition) {
        return request(url, "DELETE", apiKey, condition, [], 1, {});
    };
}
exports.createDELETE = createDELETE;
