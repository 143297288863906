import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";
import NumberExample from "./NumberInputExample";
import NumberInputInfoError from "./NumberInputInfoError";
import NumberInputInlineSuggestion from "./NumberInputInlineSuggestion";
import fieldProperties from "./Field";

export default function NumberInputDoc() {
  return (
    <>
      <Typo variant="h1">NumberInput</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description={
              <>
                A Numberfield for your forms. Should be wrapped around the{" "}
                <Typo variant="a" to="/froms/form">
                  Form component
                </Typo>
                . This component is best for integer inputs (floats are not
                supported jet but coming soon). NumberInput extends the flex Col
                component, so you dont need to wrap it in a Col and can directly
                use it as a child of a Row or a Form component.
              </>
            }
            extends={[{ label: "Col", to: "/flex/col" }]}
            properties={[
              {
                name: "value",
                default: "undefined",
                type: ["number", "undefined"],
                description: "Value of the input field.",
              },
              {
                name: "onChange",
                default: "() => {}",
                type: ["(number?: string) => void", "undefined"],
                description:
                  "Function triggers when value changes from user input.",
              },
              ...fieldProperties,
              {
                name: "suggestions",
                default: "undefined",
                type: ["number[]", "undefined"],
                description:
                  "if set it will show a menu with select options beneth the input.",
              },
              {
                name: "onSuggestionSelect",
                default: "undefined",
                type: ["(suggested: number) => void", "undefined"],
                description: "Will trigger when a suggested option is clicked.",
              },
              {
                name: "override",
                default: "undefined",
                type: [
                  {
                    "label?": "string",
                    "input?": "string",
                    "suggestList?": "string",
                    "suggestItem?": "string",
                    "col?": "string",
                  },
                  "undefined",
                ],
                description:
                  "Override property for css class injections. See customization section for more info.",
              },
            ]}
          />
          <Example
            label="Example"
            example={<NumberExample />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { NumberInput, Form } from "@maxeb/admin-ui";\n\nexport default function NumberInputExample() {\n  const [value, setValue] = useState<number | undefined>();\n  return (\n    <Form>\n      <NumberInput label="NumberInput" value={value} onChange={setValue} />\n    </Form>\n  );\n}'
            }
          />
          <Example
            label="Example Open Info/Error"
            example={<NumberInputInfoError />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { NumberInput, Form, Button } from "@maxeb/admin-ui";\n\nexport default function NumberInputInfoError() {\n  const [value, setValue] = useState<number | undefined>();\n  const [error, setError] = useState<string | undefined>();\n\n  return (\n    <Form vertical spacing={16} root={0} horizontalAlign="right">\n      <NumberInput\n        sm="rest"\n        label="NumberInput"\n        open\n        value={value}\n        onChange={setValue}\n        info="A info block you can set to inform."\n        error={error}\n      />\n      <Button\n        xs="120px"\n        danger\n        alignFloating\n        onClick={() => {\n          if (!error) setError("A test error for your input!");\n          else setError(undefined);\n        }}\n      >\n        Error\n      </Button>\n    </Form>\n  );\n}'
            }
          />
          <Example
            label="Example Inline + suggestion"
            example={<NumberInputInlineSuggestion />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { Form, NumberInput } from "@maxeb/admin-ui";\n\nexport default function NumberInputInlineSuggestion() {\n  const [value, setValue] = useState<number | undefined>();\n  const [suggestions, setSuggestions] = useState<number[] | undefined>();\n\n  const toggleSuggestions = (show: boolean) => {\n    if (!show) setSuggestions(undefined);\n    else setSuggestions([1, 2, 3]);\n  };\n\n  return (\n    <Form>\n      <NumberInput\n        label="NumberInput"\n        inline\n        value={value}\n        onChange={setValue}\n        suggestions={suggestions}\n        onFocus={() => toggleSuggestions(true)}\n        onBlur={() => toggleSuggestions(false)}\n        onSuggestionSelect={(option) => {\n          setValue(option);\n          toggleSuggestions(false);\n        }}\n      />\n    </Form>\n  );\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
