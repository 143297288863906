import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";
import ButtonExample from "./ButtonExample";

export default function RadioDoc() {
  return (
    <>
      <Typo variant="h1">Button</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description={
              <>
                A Button component for easy use. . Button extends the flex Col
                component, so you dont need to wrap it in a Col and can directly
                use it as a child of a Row or a Form component.
              </>
            }
            extends={[{ label: "Col", to: "/flex/col" }]}
            properties={[
              {
                name: "disabled",
                default: "undefined",
                type: ["boolean", "undefined"],
                description: "Disables button and applies disable style.",
              },
              {
                name: "primary",
                default: "undefined",
                type: ["boolean", "undefined"],
                description: "Applies primary style.",
              },
              {
                name: "secondary",
                default: "undefined",
                type: ["boolean", "undefined"],
                description: "Applies secondary style.",
              },
              {
                name: "tertiary",
                default: "undefined",
                type: ["boolean", "undefined"],
                description: "Applies primary style.",
              },
              {
                name: "success",
                default: "undefined",
                type: ["boolean", "undefined"],
                description: "Applies success style.",
              },
              {
                name: "warning",
                default: "undefined",
                type: ["boolean", "undefined"],
                description: "Applies warning style.",
              },
              {
                name: "danger",
                default: "undefined",
                type: ["boolean", "undefined"],
                description: "Applies danger style.",
              },
              {
                name: "type",
                default: "button",
                type: ["button", "submit", "reset"],
                description: "Sets button type.",
              },
              {
                name: "submit",
                default: "undefined",
                type: ["boolean", "undefined"],
                description: "Shorthand for type submit",
              },
              {
                name: "onClick",
                default: "undefined",
                type: ["() => void", "undefined"],
                description: "Triggers when button is clicked.",
              },
              {
                name: "width",
                default: "undefined",
                type: ["string", "number", "undefined"],
                description:
                  "Sets width of button. Only use it if you dont use button in a flex context else use col props on Button component.",
              },
              {
                name: "alignFloating",
                default: "undefined",
                type: ["boolean", "undefined"],
                description:
                  "Adds margin top to align with floating or open form elements.",
              },
            ]}
          />
          <Example
            label="Example inline"
            example={<ButtonExample />}
            language="typescript"
            code={
              'import React from "react";\nimport { Button, Form } from "@maxeb/admin-ui";\n\nexport default function FormExample() {\n  return (\n    <Form vertical spacing={16} root={0}>\n      <Button\n        xs="120px"\n        primary\n        onClick={() => {\n          alert("Clicked on primary!");\n        }}\n      >\n        Primary\n      </Button>\n      <Button\n        xs="120px"\n        secondary\n        onClick={() => {\n          alert("Clicked on secondary!");\n        }}\n      >\n        Secondary\n      </Button>\n      <Button\n        xs="120px"\n        tertiary\n        onClick={() => {\n          alert("Clicked on tertiary!");\n        }}\n      >\n        Tertiary\n      </Button>\n      <Button\n        xs="120px"\n        success\n        onClick={() => {\n          alert("Clicked on success!");\n        }}\n      >\n        Success\n      </Button>\n      <Button\n        xs="120px"\n        warning\n        onClick={() => {\n          alert("Clicked on warning!");\n        }}\n      >\n        Warning\n      </Button>\n      <Button\n        xs="120px"\n        danger\n        onClick={() => {\n          alert("Clicked on danger!");\n        }}\n      >\n        Danger\n      </Button>\n    </Form>\n  );\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
