import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";
import SelectExample from "./SelectExample";
import SelectSearchabelExample from "./SelectSearchabelExample";
import SelectInfoErrorExample from "./SelectInfoErrorExample";
import fieldProperties from "./Field";

export default function DateInputDoc() {
  return (
    <>
      <Typo variant="h1">Select</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description={
              <>
                A field you can use to let the user select one of a set of
                options. This component should be wrapped in a{" "}
                <Typo variant="a" to="/froms/form">
                  Form component
                </Typo>
                . Select extends the flex Col component, so you dont need to
                wrap it in a Col and can directly use it as a child of a Row or
                a Form component.
              </>
            }
            extends={[{ label: "Col", to: "/flex/col" }]}
            properties={[
              {
                name: "value",
                default: "undefined",
                type: ["number", "string"],
                description: "Value of the select field.",
              },
              {
                name: "options",
                default: "-",
                type: ["{ value: string | number; label: string }[]"],
                required: true,
                description:
                  "Function triggers when value changes from user input.",
              },
              {
                name: "onChange",
                default: "() => {}",
                type: ["(value?: string | number) => void", "undefined"],
                description:
                  "Function triggers when value changes from user input.",
              },
              {
                name: "searchAble",
                default: "boolean",
                type: ["boolean", "undefined"],
                description:
                  "Allows search for options with a textinput field.",
              },
              {
                name: "searchLabel",
                default: "undefined",
                type: ["string", "undefined"],
                description: "Label in the search input field (if searchable).",
              },
              {
                name: "noneFound",
                default: "undefined",
                type: ["string", "undefined"],
                description: "Label for no option found (if searchable).",
              },
              ...fieldProperties,
              {
                name: "override",
                default: "undefined",
                type: [
                  {
                    "indicator?": "string",
                    "indicatorOpen?": "string",
                    "label?": "string",
                    "input?": "string",
                    "col?": "string",
                  },
                  "undefined",
                ],
                description:
                  "Override property for css class injections. See customization section for more info.",
              },
            ]}
          />
          <Example
            label="Example"
            example={<SelectExample />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { Select, Form } from "@maxeb/admin-ui";\n\nexport default function SelectExample() {\n  const [value, setValue] = useState<string | undefined>();\n\n  return (\n    <Form>\n      <Select\n        label="Select"\n        value={value}\n        options={[\n          { label: "Option 1", value: "option1" },\n          { label: "Option 2", value: "option2" },\n          { label: "Option 3", value: "option3" },\n          { label: "Option 4", value: "option4" },\n          { label: "Option 5", value: "option5" },\n        ]}\n        onChange={(value) => {\n          if (typeof value !== "number") setValue(value);\n        }}\n      />\n    </Form>\n  );\n}'
            }
          />
          <Example
            label="Example open & search"
            example={<SelectSearchabelExample />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { Select, Form } from "@maxeb/admin-ui";\n\nexport default function SelectInputExample() {\n  const [value, setValue] = useState<string | number | undefined>();\n\n  return (\n    <Form>\n      <Select\n        open\n        label="Select"\n        value={value}\n        searchAbel\n        searchLabel="Search"\n        noneFound="None found!"\n        options={[\n          { label: "Option 1", value: "option1" },\n          { label: "Option 2", value: "option2" },\n          { label: "Option 3", value: "option3" },\n          { label: "Option 4", value: "option4" },\n          { label: "Option 5", value: "option5" },\n        ]}\n        onChange={setValue}\n      />\n    </Form>\n  );\n}'
            }
          />
          <Example
            label="Example Inline Info & Error"
            example={<SelectInfoErrorExample />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { Select, Form, Button } from "@maxeb/admin-ui";\n\nexport default function SelectInputExample() {\n  const [value, setValue] = useState<string | number | undefined>();\n  const [error, setError] = useState<string | undefined>();\n\n  return (\n    <Form spacing={16} root={0}>\n      <Select\n        xs="rest"\n        inline\n        label="Select"\n        value={value}\n        searchAbel\n        searchLabel="Search"\n        noneFound="None found!"\n        info="A info under your select field."\n        error={error}\n        options={[\n          { label: "Option 1", value: "option1" },\n          { label: "Option 2", value: "option2" },\n          { label: "Option 3", value: "option3" },\n          { label: "Option 4", value: "option4" },\n          { label: "Option 5", value: "option5" },\n        ]}\n        onChange={setValue}\n      />\n      <Button\n        xs="120px"\n        danger\n        onClick={() => {\n          if (!error) setError("A test error for your input!");\n          else setError(undefined);\n        }}\n      >\n        Error\n      </Button>\n    </Form>\n  );\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
