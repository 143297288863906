import React, { useState } from "react";
import { Checkbox, Form, Button, Col, Row } from "@maxeb/admin-ui";

export default function RadioInfoErrorExample() {
  const [value1, setValue1] = useState<boolean | undefined>();
  const [value2, setValue2] = useState<boolean | undefined>();
  const [error, setError] = useState<string | undefined>();

  return (
    <Form spacing={16} root={0}>
      <Col sm="rest">
        <Row>
          <Checkbox
            inline
            xs={5}
            label="Option 1"
            value={value1}
            onChange={setValue1}
            info="A info for your checkbox"
            error={error}
          />
          <Checkbox
            inline
            xs={5}
            label="Option 2"
            value={value2}
            onChange={setValue2}
            info="A info for your checkbox"
            error={error}
          />
        </Row>
      </Col>
      <Button
        xs="120px"
        danger
        onClick={() => {
          if (!error) setError("A test error for your input!");
          else setError(undefined);
        }}
      >
        Error
      </Button>
    </Form>
  );
}
