import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import fieldProperties from "./Field";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";
import TextExample from "./TextInputExample";
import TextInputInfoError from "./TextInputInfoError";
import TextInputInlineSuggestion from "./TextInputInlineSuggestion";

export default function FormDoc() {
  return (
    <>
      <Typo variant="h1">TextInput</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description={
              <>
                A Textfield for your forms. Should be wrapped around the{" "}
                <Typo variant="a" to="/froms/form">
                  Form component
                </Typo>
                . This component is best for small to large text input.
                TextInput extends the flex Col component, so you dont need to
                wrap it in a Col and can directly use it as a child of a Row or
                a Form component.
              </>
            }
            extends={[{ label: "Col", to: "/flex/col" }]}
            properties={[
              {
                name: "value",
                default: "undefined",
                type: ["string", "undefined"],
                description: "Value of the input field.",
              },
              {
                name: "onChange",
                default: "() => {}",
                type: ["(value?: string) => void", "undefined"],
                description:
                  "Function triggers when value changes from user input.",
              },
              {
                name: "rows",
                default: "1",
                type: ["number", "undefined"],
                description:
                  "Number of rows you need the input to be. If not defined it will be one line.",
              },
              {
                name: "password",
                default: "false",
                type: ["boolean", "undefined"],
                description:
                  "Set this property to hide characters and decalare field a password input.",
              },
              ...fieldProperties,
              {
                name: "suggestions",
                default: "undefined",
                type: ["string[]", "undefined"],
                description:
                  "if set it will show a menu with select options beneth the input.",
              },
              {
                name: "onSuggestionSelect",
                default: "undefined",
                type: ["(suggested: string) => void", "undefined"],
                description: "Will trigger when a suggested option is clicked.",
              },
              {
                name: "override",
                default: "undefined",
                type: [
                  {
                    "label?": "string",
                    "input?": "string",
                    "suggestList?": "string",
                    "suggestItem?": "string",
                    "col?": "string",
                  },
                  "undefined",
                ],
                description:
                  "Override property for css class injections. See customization section for more info.",
              },
            ]}
          />
          <Example
            label="Example"
            example={<TextExample />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { TextInput, Form } from "@temeium/uui";\n\nexport default function TextExample() {\n  const [value, setValue] = useState<string | undefined>();\n\n  return (\n    <Form>\n      <TextInput label="TextInput" value={value} onChange={setValue} />\n    </Form>\n  );\n}'
            }
          />
          <Example
            label="Example Open Info/Error"
            example={<TextInputInfoError />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { TextInput, Form, Button } from "@temeium/uui";\n\nexport default function TextInputInfoError() {\n  const [value, setValue] = useState<string | undefined>();\n  const [error, setError] = useState<string | undefined>();\n\n  return (\n    <Form vertical spacing={16} root={0} horizontalAlign="right">\n      <TextInput\n        sm="rest"\n        label="TextInput"\n        open\n        value={value}\n        onChange={setValue}\n        info="A info block you can set to inform."\n        error={error}\n      />\n      <Button\n        xs="120px"\n        danger\n        alignFloating\n        onClick={() => {\n          if (!error) setError("A test error for your input!");\n          else setError(undefined);\n        }}\n      >\n        Error\n      </Button>\n    </Form>\n  );\n}'
            }
          />
          <Example
            label="Example Inline + suggestion"
            example={<TextInputInlineSuggestion />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { TextInput, Form } from "@temeium/uui";\n\nexport default function TextInputInlineSuggestion() {\n  const [value, setValue] = useState<string | undefined>();\n  const [suggestions, setSuggestions] = useState<string[] | undefined>();\n\n  const toggleSuggestions = (show: boolean) => {\n    if (!show) setSuggestions(undefined);\n    else setSuggestions(["Option 1", "Option 2", "Option 3"]);\n  };\n\n  return (\n    <Form>\n      <TextInput\n        label="TextInput"\n        inline\n        value={value}\n        onChange={setValue}\n        suggestions={suggestions}\n        onFocus={() => toggleSuggestions(true)}\n        onBlur={() => toggleSuggestions(false)}\n        onSuggestionSelect={(option) => {\n          setValue(option);\n          toggleSuggestions(false);\n        }}\n      />\n    </Form>\n  );\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
