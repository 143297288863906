import React from "react";
import { Sidebar, SidebarSection } from "@maxeb/admin-ui";

export default function SidebarSectionExample() {
  return (
    <Sidebar>
      <SidebarSection label="Label">Content</SidebarSection>
    </Sidebar>
  );
}
