import React from "react";
import { faList } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Sidebar, SidebarList, SidebarListLink } from "@maxeb/admin-ui";

export default function ListsExample() {
  return (
    <Sidebar>
      <SidebarList icon={<FontAwesomeIcon icon={faList} />} label="Section I">
        <SidebarListLink to="/sidebar/list">Link 1</SidebarListLink>
        <SidebarListLink to="/sidebar/list">Link 2</SidebarListLink>
        <SidebarListLink to="/sidebar/list">Link 3</SidebarListLink>
      </SidebarList>
    </Sidebar>
  );
}
