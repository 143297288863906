import React, { useState } from "react";
import { TextInput, Form } from "@maxeb/admin-ui";

export default function TextInputInlineSuggestion() {
  const [value, setValue] = useState<string | undefined>();
  const [suggestions, setSuggestions] = useState<string[] | undefined>();
  const toggleSuggestions = (show: boolean) => {
    if (!show) setSuggestions(undefined);
    else setSuggestions(["Option 1", "Option 2", "Option 3"]);
  };
  return (
    <Form>
      <TextInput
        label="TextInput"
        inline
        value={value}
        onChange={setValue}
        suggestions={suggestions}
        onFocus={() => toggleSuggestions(true)}
        onBlur={() => toggleSuggestions(false)}
        onSuggestionSelect={(option: string) => {
          setValue(option);
          toggleSuggestions(false);
        }}
      />
    </Form>
  );
}
