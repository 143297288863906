import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";
import ListExample from "./ListExample";
import ListExampleCollapsable from "./ListExampleCollapsable";

export default function SidebarListDoc() {
  return (
    <>
      <Typo variant="h1">SidebarList</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description={
              <>
                A List wrapper which changes styles of List comoponent for use
                in sidebar. It has exactly the same properties as normal List
                component.
              </>
            }
            extends={[{ label: "List", to: "/lists/list" }]}
            properties={[]}
          />
          <Example
            label="Example"
            example={<ListExample />}
            language="typescript"
            code={
              'import React from "react";\nimport { faList } from "@fortawesome/pro-light-svg-icons";\nimport { FontAwesomeIcon } from "@fortawesome/react-fontawesome";\nimport { Sidebar, SidebarList, SidebarListLink } from "@maxeb/admin-ui";\n\nexport default function SidebarListsExample() {\n  return (\n    <Sidebar>\n      <SidebarList icon={<FontAwesomeIcon icon={faList} />} label="Section I">\n        <SidebarListLink to="/sidebar/list">Link 1</SidebarListLink>\n        <SidebarListLink to="/sidebar/list">Link 2</SidebarListLink>\n        <SidebarListLink to="/sidebar/list">Link 3</SidebarListLink>\n      </SidebarList>\n    </Sidebar>\n  );\n}'
            }
          />
          <Example
            label="Example collapseAble & open"
            example={<ListExampleCollapsable />}
            language="typescript"
            code={
              'import React from "react";\nimport { faList } from "@fortawesome/pro-light-svg-icons";\nimport { FontAwesomeIcon } from "@fortawesome/react-fontawesome";\nimport { Sidebar, SidebarList, SidebarListLink } from "@maxeb/admin-ui";\n\nexport default function SidebarListsExample() {\n  return (\n    <Sidebar>\n      <SidebarList\n        icon={<FontAwesomeIcon icon={faList} />}\n        label="Section I"\n        collapseAble\n        open\n      >\n        <SidebarListLink to="/sidebar/list">Link 1</SidebarListLink>\n        <SidebarListLink to="/sidebar/list">Link 2</SidebarListLink>\n        <SidebarListLink to="/sidebar/list">Link 3</SidebarListLink>\n      </SidebarList>\n    </Sidebar>\n  );\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
