import React from "react";
import { useI18n, Typo } from "@maxeb/admin-ui";

export default function I18nGetTranslation() {
  //create hook and set tanslation group
  const i18n = useI18n("example");
  return (
    <Typo variant="p">
      {
        //get translation value as string by its key
        i18n.get("helloWorldTranslation")
      }
    </Typo>
  );
}
