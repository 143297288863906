import React, { useState } from "react";
import { Form, NumberInput } from "@maxeb/admin-ui";

export default function NumberInputInlineSuggestion() {
  const [value, setValue] = useState<number | undefined>();
  const [suggestions, setSuggestions] = useState<number[] | undefined>();

  const toggleSuggestions = (show: boolean) => {
    if (!show) setSuggestions(undefined);
    else setSuggestions([1, 2, 3]);
  };

  return (
    <Form>
      <NumberInput
        label="NumberInput"
        inline
        value={value}
        onChange={setValue}
        suggestions={suggestions}
        onFocus={() => toggleSuggestions(true)}
        onBlur={() => toggleSuggestions(false)}
        onSuggestionSelect={(option: number | undefined) => {
          setValue(option);
          toggleSuggestions(false);
        }}
      />
    </Form>
  );
}
