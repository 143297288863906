import React, { useState } from "react";
import { Form, Button, UploadArea } from "@maxeb/admin-ui";

export default function UploadAreaInfoError() {
  const [value, setValue] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();

  return (
    <Form vertical spacing={16} root={0} horizontalAlign="right">
      <UploadArea
        sm="rest"
        inline
        height="150px"
        value={value}
        onChange={setValue}
        info="A info block you can set to inform."
        error={error}
      >
        Upload File
      </UploadArea>
      <Button
        xs="120px"
        danger
        onClick={() => {
          if (!error) setError("A test error for your input!");
          else setError(undefined);
        }}
      >
        Error
      </Button>
    </Form>
  );
}
