import React from "react";
import { faList } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Row,
  Sidebar,
  SidebarLink,
  SidebarList,
  SidebarSection,
} from "@maxeb/admin-ui";

export default function ExampleSidebarLists() {
  return (
    <>
      <h1>Sidebar</h1>
      <Row root={0} spacing={16}>
        <Col md={10 / 3}>
          <Sidebar>
            <SidebarSection label="SidebarSection 1">
              <SidebarList
                icon={<FontAwesomeIcon icon={faList} />}
                label="SidebarList"
              >
                <SidebarLink to="/sidebar/example">SidebarLink 1</SidebarLink>
                <SidebarLink to="/sidebar/example">SidebarLink 2</SidebarLink>
                <SidebarLink to="/sidebar/example">SidebarLink 3</SidebarLink>
              </SidebarList>
            </SidebarSection>
            <SidebarSection label="SidebarSection 2">
              <SidebarList
                icon={<FontAwesomeIcon icon={faList} />}
                label="SidebarList CollapseAble"
                collapseAble
              >
                <SidebarLink to="/sidebar/example">SidebarLink 1</SidebarLink>
                <SidebarLink to="/sidebar/example">SidebarLink 2</SidebarLink>
                <SidebarLink to="/sidebar/example">SidebarLink 3</SidebarLink>
              </SidebarList>
            </SidebarSection>
            <SidebarSection label="SidebarSection 3">
              <SidebarList
                icon={<FontAwesomeIcon icon={faList} />}
                label="SidebarList CollapseAble Open"
                collapseAble
                open
              >
                <SidebarLink to="/sidebar/example">SidebarLink 1</SidebarLink>
                <SidebarLink to="/sidebar/example">SidebarLink 2</SidebarLink>
                <SidebarLink to="/sidebar/example">SidebarLink 3</SidebarLink>
              </SidebarList>
            </SidebarSection>
          </Sidebar>
        </Col>
      </Row>
    </>
  );
}
