import React, { useState } from "react";
import { DateInput, Form } from "@maxeb/admin-ui";

export default function DateInputExample() {
  const [value, setValue] = useState<[string, string, string] | undefined>();
  return (
    <Form>
      <DateInput label="DateInput" value={value} onChange={setValue} />
    </Form>
  );
}
