import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";
import SidebarExample from "./SidebarExample";

export default function SidebarListDoc() {
  return (
    <>
      <Typo variant="h1">Sidebar</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description={
              <>
                A Sidebar element you can use with some Layouts or use it to
                create one of your own. <br />
                Sidebar will allways be 100vh heigh. Overflow is set to scroll
                and scrollbar will be hidden.
              </>
            }
            properties={[
              {
                name: "logo",
                default: "undefined",
                type: ["React.ReactNode", "undefined"],
                description: "Logo will be placed top in sidebar.",
              },
              {
                name: "children",
                default: "undefined",
                type: ["React.ReactNode", "undefined"],
                description: "Content for sidebar.",
              },
              {
                name: "onClose",
                default: "() => {}",
                type: ["() => void", "undefined"],
                description:
                  "Will fire on close of sidebar. Button is only visible on small displays.",
              },
            ]}
          />
          <Example
            label="Example"
            example={<SidebarExample />}
            language="typescript"
            code={
              'import React from "react";\nimport { faList } from "@fortawesome/pro-light-svg-icons";\nimport { FontAwesomeIcon } from "@fortawesome/react-fontawesome";\nimport {\n  Sidebar,\n  SidebarLink as Link,\n  SidebarList as List,\n  SidebarSection as Section,\n} from "@maxeb/admin-ui";\n\nexport default function SidebarExample() {\n  return (\n    <Sidebar logo={<div style={{ padding: "8px" }}>Logo</div>}>\n      <Section label="SidebarSection 1">\n        <List icon={<FontAwesomeIcon icon={faList} />} label="SidebarList">\n          <Link to="/sidebar/example">SidebarLink 1</Link>\n          <Link to="/sidebar/example">SidebarLink 2</Link>\n          <Link to="/sidebar/example">SidebarLink 3</Link>\n        </List>\n      </Section>\n      <Section label="SidebarSection 2">\n        <List\n          icon={<FontAwesomeIcon icon={faList} />}\n          label="SidebarList CollapseAble"\n          collapseAble\n        >\n          <Link to="/sidebar/example">SidebarLink 1</Link>\n          <Link to="/sidebar/example">SidebarLink 2</Link>\n          <Link to="/sidebar/example">SidebarLink 3</Link>\n        </List>\n      </Section>\n      <Section label="SidebarSection 3">\n        <List\n          icon={<FontAwesomeIcon icon={faList} />}\n          label="SidebarList CollapseAble Open"\n          collapseAble\n          open\n        >\n          <Link to="/sidebar/example">SidebarLink 1</Link>\n          <Link to="/sidebar/example">SidebarLink 2</Link>\n          <Link to="/sidebar/example">SidebarLink 3</Link>\n        </List>\n      </Section>\n    </Sidebar>\n  );\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
