import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { init, Router } from "@maxeb/admin-ui";

import { options as IDPOptions } from "@datenbanker/idp-sdk";

IDPOptions.setApiKey("ks4zlMK0DZ8hX0nub9Uv38n4oxGnbq2M7CHtKkTQ");

const options = {};
init(options);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
