import React from "react";
import { useClasses, ITheme, Block, Col, Row, Typo } from "@maxeb/admin-ui";

interface MyBlueDivFCProps {}

const styles = (theme: ITheme) => ({
  border: {
    border: "1px solid " + theme.palette.get("primary"),
  },
});

export default function RowCol(props: MyBlueDivFCProps) {
  const classes = useClasses(styles, props);
  return (
    <>
      <Typo variant="p" color="primary">
        Row
      </Typo>
      <Row vertical spacing={16} root={8} override={{ row: classes.border }}>
        <Col md={5} lg={10 / 3}>
          <Block>
            Col
            <br />
            md=5
            <br />
            lg=10 / 3
          </Block>
        </Col>
        <Col md={5} lg={10 / 3}>
          <Block>
            Col
            <br />
            md=5
            <br />
            lg=10 / 3
          </Block>
        </Col>
        <Col md={5} lg={10 / 3}>
          <Block>
            Col
            <br />
            md=5
            <br />
            lg=10 / 3
          </Block>
        </Col>
      </Row>
    </>
  );
}
