import React, { useState } from "react";
import { Radio, RadioItem, Form, Button } from "@maxeb/admin-ui";

export default function RadioInfoErrorExample() {
  const [value, setValue] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  return (
    <Form spacing={16} root={0}>
      <Radio
        sm="rest"
        label="Select"
        info="A info for your radio boxes."
        error={error}
        value={value}
        onChange={setValue}
      >
        <RadioItem sm={5} lg={10 / 4} label="Option 1" value="option1" />
        <RadioItem sm={5} lg={10 / 4} label="Option 2" value="option2" />
        <RadioItem sm={5} lg={10 / 4} label="Option 3" value="option3" />
      </Radio>
      <Button
        xs="120px"
        alignFloating
        danger
        onClick={() => {
          if (!error) setError("A test error for your input!");
          else setError(undefined);
        }}
      >
        Error
      </Button>
    </Form>
  );
}
