import React, { useState } from "react";
import { Select, Form, Button } from "@maxeb/admin-ui";

export default function SelectInputExample() {
  const [value, setValue] = useState<string | number | undefined>();
  const [error, setError] = useState<string | undefined>();
  return (
    <Form spacing={16} root={0}>
      <Select
        xs="rest"
        inline
        label="Select"
        value={value}
        searchAbel
        searchLabel="Search"
        noneFound="None found!"
        info="A info under your select field."
        error={error}
        options={[
          { label: "Option 1", value: "option1" },
          { label: "Option 2", value: "option2" },
          { label: "Option 3", value: "option3" },
          { label: "Option 4", value: "option4" },
          { label: "Option 5", value: "option5" },
        ]}
        onChange={setValue}
      />
      <Button
        xs="120px"
        danger
        onClick={() => {
          if (!error) setError("A test error for your input!");
          else setError(undefined);
        }}
      >
        Error
      </Button>
    </Form>
  );
}
