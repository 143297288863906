import React from "react";
import { Block, ITheme, useClasses } from "@maxeb/admin-ui";

const styles = (theme: ITheme) => ({
  customClass: {
    backgroundColor: "black !important",
    color: "white",
  },
});

export default function CustomizedBlock() {
  const classes = useClasses(styles);
  return (
    <Block override={{ wrapper: classes.customClass }}>
      Hello I am a customized Block component!
    </Block>
  );
}
