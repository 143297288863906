import React from "react";
import { faList } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Sidebar,
  SidebarLink as Link,
  SidebarList as List,
  SidebarSection as Section,
} from "@maxeb/admin-ui";

interface IProps {
  onClose?: () => void;
}

export default function SidebarExample(props: IProps) {
  return (
    <Sidebar
      logo={<div style={{ padding: "8px" }}>Logo</div>}
      onClose={props.onClose}
    >
      <Section label="SidebarSection 1">
        <List icon={<FontAwesomeIcon icon={faList} />} label="SidebarList">
          <Link to="/sidebar/example">SidebarLink 1</Link>
          <Link to="/sidebar/example">SidebarLink 2</Link>
          <Link to="/sidebar/example">SidebarLink 3</Link>
        </List>
      </Section>
      <Section label="SidebarSection 2">
        <List
          icon={<FontAwesomeIcon icon={faList} />}
          label="SidebarList CollapseAble"
          collapseAble
        >
          <Link to="/sidebar/example">SidebarLink 1</Link>
          <Link to="/sidebar/example">SidebarLink 2</Link>
          <Link to="/sidebar/example">SidebarLink 3</Link>
        </List>
      </Section>
      <Section label="SidebarSection 3">
        <List
          icon={<FontAwesomeIcon icon={faList} />}
          label="SidebarList CollapseAble Open"
          collapseAble
          open
        >
          <Link to="/sidebar/example">SidebarLink 1</Link>
          <Link to="/sidebar/example">SidebarLink 2</Link>
          <Link to="/sidebar/example">SidebarLink 3</Link>
        </List>
      </Section>
    </Sidebar>
  );
}
