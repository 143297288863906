import React, { useState } from "react";
import { Form, UploadArea } from "@maxeb/admin-ui";

export default function CheckboxExample() {
  const [value, setValue] = useState<string | undefined>();

  return (
    <Form spacing={16} root={0}>
      <UploadArea
        height="150px"
        value={value}
        onChange={(file?: string) => {
          console.log(file);
          setValue(file);
        }}
      >
        Upload file!
      </UploadArea>
    </Form>
  );
}
