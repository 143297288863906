import React from "react";
import { AdminLayout } from "@maxeb/admin-ui";
import SidebarExample from "../sidebar/SidebarExample";

export default function AdminLayoutExample() {
  return (
    <div style={{ border: "1px solid" }}>
      <AdminLayout topbar="topbar-content" sidebar={<SidebarExample />}>
        Page-Content
      </AdminLayout>
    </div>
  );
}
