import React from "react";
import { Block, Typo, Row, Col } from "@maxeb/admin-ui";
import CodeViewer from "./CodeViewer";

export interface IDocumetationBlock {
  label?: React.ReactNode;
  description?: React.ReactNode;
  language?: "bash" | "css" | "typescript";
  steps?: { label?: React.ReactNode; code?: string }[];
  exampleDescrtiption?: React.ReactNode;
  exampleCode?: string;
  example?: React.ReactNode;
}

export default function DocumentationBlock(props: IDocumetationBlock) {
  return (
    <React.Fragment>
      {typeof props.label !== "undefined" && (
        <Typo variant="section">{props.label}</Typo>
      )}
      <Block>
        <Row
          root={0}
          vertical
          spacing={16}
          horizontalAlign="right"
          verticalAlign="bottom"
        >
          {typeof props.description !== "undefined" && (
            <Col>
              <Typo variant="h3" margin="none" color="primary">
                Description
              </Typo>
              <Typo variant="p" margin="8px 0px 0px 0px">
                {props.description}
              </Typo>
            </Col>
          )}
          <Col>
            <Typo variant="h3" margin="none" color="primary">
              Steps
            </Typo>
            {props.steps?.map((step, index) => (
              <React.Fragment key={"step-" + props.label + "-" + index}>
                <Typo variant="p" margin={"16px 0px 8px"}>
                  <b>{index + 1}.</b> {step.label}
                </Typo>
                <CodeViewer language={props.language || "typescript"}>
                  {step.code}
                </CodeViewer>
              </React.Fragment>
            ))}
          </Col>
          {typeof props.exampleCode !== "undefined" && (
            <Col>
              <Typo variant="h3" margin="none" color="primary">
                Full example
              </Typo>
              {typeof props.exampleDescrtiption !== "undefined" && (
                <Typo variant="p" margin="8px 0px">
                  {props.exampleDescrtiption}
                </Typo>
              )}
              <CodeViewer language={props.language || "typescript"}>
                {props.exampleCode}
              </CodeViewer>
            </Col>
          )}
          {typeof props.example !== "undefined" && (
            <Col>
              <Typo variant="h4" margin="0px 0px 8px 0px">
                Rendered example
              </Typo>
              {props.example}
            </Col>
          )}
        </Row>
      </Block>
    </React.Fragment>
  );
}
