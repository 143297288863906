import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";

export default function AuthenticationDoc() {
  return (
    <>
      <Typo variant="h1">SingelPageAuthLayout</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description={
              <>
                This Layout provides you a singel page authentication. You can
                add small amount of content left of signup like logo or short
                text.
                <br />
                Since this layout does not support routing there is no managed
                version of it.
              </>
            }
            requires={[
              <>I18n configured.</>,
              <>@datenbanker/idp-sdk initialised.</>,
            ]}
            properties={[
              {
                name: "session",
                required: true,
                type: ["ISession ", "null"],
                description:
                  "Current session provided by useSession Hook. Is null if not signed in or while session check is pending.",
              },
              {
                name: "setSession",
                required: true,
                type: ["(session: ISession | null) => void"],
                description:
                  "SetSession is provided by useSession Hook. It is needed by the component to change session on sertain events.",
              },
              {
                name: "userGroupId",
                default: "undefined",
                type: ["string", "undefined"],
                description:
                  "If your authentication is fixed on only one UserGroup or you are using deeplinks for registration you can provide usergroup id at this propertie.",
              },
              {
                name: "translationGroup",
                default: "authentication",
                type: ["string", "undefined"],
                description:
                  "The i18n group in your translation files, by default it searchs for authentication translation group.",
              },
              {
                name: "children",
                default: "undefined",
                type: ["React.ReactNode", "undefined"],
                description: "Small amount of content next to authenticator.",
              },
            ]}
          />
          <Example
            label="Example"
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport {\n  useSession,\n  Typo,\n  Row,\n  SingelPageAuthLayout,\n} from "@maxeb/admin-ui";\nimport Content from "./Content";\n\nexport default function ExampleSingelPageAuth() {\n  const [session, setSession] = useSession(null);\n\n if (session === null)\n    return (\n      <SingelPageAuthLayout session={session} setSession={setSession}>\n        <Row spacing={16} vertical>\n          Your Content goes here\n          <Typo variant="a" to="/">\n            Back\n          </Typo>\n        </Row>\n      </SingelPageAuthLayout>\n    );\n\n    return <Content/>;\n}'
            }
          />
          <Example
            label="I18n translation json"
            language="typescript"
            code={
              '{\n    "authentication": {\n        "signIn": "Sign In",\n        "username": "Username",\n        "password": "Password",\n        "forgot_password?": "Forgot password?",\n        "signUp?": "Sign up?",\n        "signUp": "Sign up",\n        "company_data": "Company data",\n        "company_account_id": "Company account id",\n        "user_data": "user data",\n        "firstname": "Firstname",\n        "lastname": "Lastname",\n        "email/username": "Email / Username",\n        "agbs": "You comply to our agbs.",\n        "dsgvo": "You accept our dsgvo.",\n        "You_already_have_an_Account?": "You already have an account?",\n        "reset_password": "Reset password",\n        "please_enter_account_email": "Please enter your email address.",\n        "email": "Email",\n        "continue": "Continue",\n        "back_to_sign_in?": "Back to sign in?",\n        "new password": "New password",\n        "enter_code_password_reset": "We sent you a code via email to reset your password, please enter it here.",\n        "code": "Code",\n        "change": "Change",\n        "new_password": "New password",\n        "verify_email": "Verify email",\n        "verify_email_info": "We sent you a code via email to verify your email address.",\n        "verify": "Verify",\n        "a": "A",\n        "unknown Error": "unknown error",\n        "occurred": "occurred"\n    }\n}'
            }
          />
          <Example
            label="@datenbanker/idp-sdk init"
            language="typescript"
            code={
              'import React from "react";\nimport ReactDOM from "react-dom";\nimport App from "./App";\nimport reportWebVitals from "./reportWebVitals";\nimport { init, Router } from "@maxeb/admin-ui";\nimport { options as IDPOptions } from "@datenbanker/idp-sdk";\n\nIDPOptions.setApiKey("<YOUR_API_KEY>");\n\nconst options = {};\ninit(options);\n\nReactDOM.render(\n  <React.StrictMode>\n    <Router>\n      <App />\n    </Router>\n  </React.StrictMode>,\n  document.getElementById("root")\n);\n// If you want to start measuring performance in your app, pass a function\n// to log results (for example: reportWebVitals(console.log))\n// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals\nreportWebVitals();'
            }
          />
        </Col>
      </Row>
    </>
  );
}
