import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";
import RadioExample from "./RadioExample";
import RadioInfoErrorExample from "./RadioInfoErrorExample";

export default function RadioDoc() {
  return (
    <>
      <Typo variant="h1">Radio</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description={
              <>
                A field you can use to let the user select one of a set of
                options. This component should be wrapped in a{" "}
                <Typo variant="a" to="/froms/form">
                  Form component
                </Typo>
                . Radio extends the flex Col component, so you dont need to wrap
                it in a Col and can directly use it as a child of a Row or a
                Form component. It also extends the flex Row component so you
                can align child elements better.
              </>
            }
            extends={[
              { label: "Col", to: "/flex/col" },
              { label: "Row", to: "/flex/row" },
            ]}
            properties={[
              {
                name: "value",
                default: "undefined",
                type: ["string", "undefined"],
                description: "Value of radio field.",
              },
              {
                name: "onChange",
                default: "() => {}",
                type: ["(checked?: string) => void"],
                description:
                  "Function triggers when value changes from user input.",
              },
              {
                name: "label",
                default: "string",
                type: ["string", "undefined"],
                description: "Label of radio field.",
              },
              {
                name: "inline",
                default: "undefined",
                type: ["boolean", "undefined"],
                description:
                  "Label will only be visible if inline value is empty or false, but fields need less space since label is not floating above them.",
              },
              {
                name: "children",
                default: "undefined",
                required: true,
                type: [
                  "ReactElement<IRadioItem>",
                  "ReactElement<IRadioItem>[]",
                ],
                description:
                  "Options you can select from. Need to be Radio item elements.",
              },
              {
                name: "info",
                default: "undefined",
                type: ["string", "undefined"],
                description:
                  "Info text shown below input field. Will not be shown if error is set.",
              },
              {
                name: "error",
                default: "undefined",
                type: ["string", "undefined"],
                description:
                  "Error text shown below input field. Will also trigger error highlighting.",
              },
              {
                name: "override",
                default: "undefined",
                type: [
                  {
                    "root?": "string",
                    "row?": "string",
                    "col?": "string",
                  },
                  "undefined",
                ],
                description:
                  "Override property for css class injections. See customization section for more info.",
              },
            ]}
          />
          <Example
            label="Example inline"
            example={<RadioExample />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { Radio, Form, RadioItem } from "@maxeb/admin-ui";\n\nexport default function RadioExample() {\n  const [value, setValue] = useState<string | undefined>();\n\n  return (\n    <Form spacing={16} root={0}>\n      <Radio label="Select" inline value={value} onChange={setValue}>\n        <RadioItem sm={5} lg={10 / 4} label="Option 1" value="option1" />\n        <RadioItem sm={5} lg={10 / 4} label="Option 2" value="option2" />\n        <RadioItem sm={5} lg={10 / 4} label="Option 3" value="option3" />\n        <RadioItem sm={5} lg={10 / 4} label="Option 4" value="option4" />\n      </Radio>\n    </Form>\n  );\n}'
            }
          />
          <Example
            label="Example search"
            example={<RadioInfoErrorExample />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { Radio, RadioItem, Form, Button } from "@maxeb/admin-ui";\n\nexport default function RadioInfoErrorExample() {\n  const [value, setValue] = useState<string | undefined>();\n  const [error, setError] = useState<string | undefined>();\n\n  return (\n    <Form spacing={16} root={0}>\n      <Radio\n        sm="rest"\n        label="Select"\n        info="A info for your radio boxes."\n        error={error}\n        value={value}\n        onChange={setValue}\n      >\n        <RadioItem sm={5} lg={10 / 4} label="Option 1" value="option1" />\n        <RadioItem sm={5} lg={10 / 4} label="Option 2" value="option2" />\n        <RadioItem sm={5} lg={10 / 4} label="Option 3" value="option3" />\n      </Radio>\n      <Button\n        xs="120px"\n        alignFloating\n        danger\n        onClick={() => {\n          if (!error) setError("A test error for your input!");\n          else setError(undefined);\n        }}\n      >\n        Error\n      </Button>\n    </Form>\n  );\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
