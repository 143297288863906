import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";
import CheckboxExample from "./CheckboxExample";
import CheckboxInfoErrorExample from "./CheckboxInfoErrorExample";

export default function RadioDoc() {
  return (
    <>
      <Typo variant="h1">Checkbox</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description={
              <>
                A checkbox for your forms. It should be wrapped in a{" "}
                <Typo variant="a" to="/froms/form">
                  Form component
                </Typo>
                . Checkbox extends the flex Col component, so you dont need to
                wrap it in a Col and can directly use it as a child of a Row or
                a Form component.
              </>
            }
            extends={[{ label: "Col", to: "/flex/col" }]}
            properties={[
              {
                name: "label",
                default: "undefined",
                type: ["string", "undefined"],
                description: "Adds a string as label next to checkbox.",
              },
              {
                name: "value",
                default: "undefined",
                type: ["boolean", "undefined"],
                description: "Toggles checked state.",
              },
              {
                name: "onChange",
                default: "() => void",
                type: ["(checked: boolean) => void", "undefined"],
                description:
                  "Function will be triggered if user changes state.",
              },

              {
                name: "children",
                default: "undefined",
                type: ["React.ReactNode", "undefined"],
                description:
                  "Elements next to checkbox. Use it if you want to use react Elements as label.",
              },
              {
                name: "inline",
                default: "undefined",
                type: ["boolean", "undefined"],
                description:
                  "Toggles inline style, will change its size to align with rest of elements.",
              },
              {
                name: "info",
                default: "undefined",
                type: ["string", "undefined"],
                description: "Info shown under the checkbox.",
              },
              {
                name: "error",
                default: "undefined",
                type: ["string", "undefined"],
                description:
                  "If set shows string under checkbox and sets error highlighting.",
              },
            ]}
          />
          <Example
            label="Example"
            example={<CheckboxExample />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { Form, Checkbox } from "@maxeb/admin-ui";\n\nexport default function CheckboxExample() {\n  const [value1, setValue1] = useState<boolean | undefined>();\n  const [value2, setValue2] = useState<boolean | undefined>();\n  const [value3, setValue3] = useState<boolean | undefined>();\n\n  return (\n    <Form spacing={16} root={0} parts={3}>\n      <Checkbox\n        sm={3}\n        lg={1}\n        label="Option 1"\n        value={value1}\n        onChange={setValue1}\n      />\n      <Checkbox\n        sm={3}\n        lg={1}\n        label="Option 2"\n        value={value2}\n        onChange={setValue2}\n      />\n      <Checkbox\n        sm={3}\n        lg={1}\n        label="Option 3"\n        value={value3}\n        onChange={setValue3}\n      />\n    </Form>\n  );\n}'
            }
          />
          <Example
            label="Example inline & error"
            example={<CheckboxInfoErrorExample />}
            language="typescript"
            code={
              'import React, { useState } from "react";\nimport { Radio, RadioItem, Form, Button } from "@maxeb/admin-ui";\n\nexport default function RadioInfoErrorExample() {\n  const [value, setValue] = useState<string | undefined>();\n  const [error, setError] = useState<string | undefined>();\n\n  return (\n    <Form spacing={16} root={0}>\n      <Radio\n        sm="rest"\n        label="Select"\n        info="A info for your radio boxes."\n        error={error}\n        value={value}\n        onChange={setValue}\n      >\n        <RadioItem sm={5} lg={10 / 4} label="Option 1" value="option1" />\n        <RadioItem sm={5} lg={10 / 4} label="Option 2" value="option2" />\n        <RadioItem sm={5} lg={10 / 4} label="Option 3" value="option3" />\n      </Radio>\n      <Button\n        xs="120px"\n        alignFloating\n        danger\n        onClick={() => {\n          if (!error) setError("A test error for your input!");\n          else setError(undefined);\n        }}\n      >\n        Error\n      </Button>\n    </Form>\n  );\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
