import React, { useState } from "react";
import {
  Row,
  Col,
  Block,
  Form as Formular,
  TextInput,
  NumberInput,
  DateInput,
  Button,
  Select,
  Radio,
  RadioItem,
  Checkbox,
  UploadArea,
  Typo,
} from "@maxeb/admin-ui";

export interface IForm {
  inline?: boolean;
  open?: boolean;
}

function Form(props: IForm) {
  const [options, setOptions] = useState<{
    showError: boolean;
    showInfo: boolean;
    errorText: string | undefined;
    infoText: string | undefined;
  }>({
    showError: false,
    errorText: "Has an error described here.",
    showInfo: false,
    infoText: "Has an additional description here.",
  });
  const { showError, showInfo } = options;

  const [radioValue, setRadioValue] = useState<string | undefined>();
  const [stringValue, setStringValue] = useState<string | undefined>();
  const [numberValue, setNumberValue] = useState<number | undefined>();
  const [dateValue, setDateValue] = useState<
    [string, string, string] | undefined
  >();
  const [selectValue, setSelect] = useState<number>(0);
  const [checkboxValue, setCheckboxValue] = useState(false);

  const inline = props.inline;
  const open = props.open;
  return (
    <Block>
      <Row spacing={16} root={0} vertical>
        <Col md="rest-fixed">
          <Typo variant="h3" color="primary" margin="0px">
            Inputs
          </Typo>
        </Col>
        <Button
          md="120px"
          danger
          onClick={() =>
            setOptions({
              ...options,
              showError: !showError,
              showInfo: false,
            })
          }
        >
          Show Error
        </Button>
        <Button
          md="120px"
          primary
          onClick={() =>
            setOptions({
              ...options,
              showError: false,
              showInfo: !showInfo,
            })
          }
        >
          Show Info
        </Button>
      </Row>
      <Formular spacing={16} root={0} vertical>
        <TextInput
          inline={inline}
          open={open}
          md={5}
          label="TextInput"
          value={stringValue}
          onChange={setStringValue}
          info={showInfo && options.infoText}
          error={showError && options.errorText}
        />
        <Radio
          inline={inline}
          xs={5}
          spacing={16}
          root={0}
          vertical
          label="Radio"
          onChange={setRadioValue}
          value={radioValue}
          info={showInfo ? options.infoText : undefined}
          error={showError ? options.errorText : undefined}
        >
          <RadioItem md={10 / 3} label="RadioItem 1" value="value1" />
          <RadioItem md={10 / 3} label="RadioItem 2" value="value2" />
          <RadioItem md={10 / 3} label="RadioItem 3" value="value3" />
        </Radio>
        <NumberInput
          inline={inline}
          open={open}
          md={5}
          label="NumberInput"
          value={numberValue}
          onChange={setNumberValue}
          info={showInfo && options.infoText}
          error={showError && options.errorText}
        />
        <Select
          inline={inline}
          open={open}
          md={5}
          label="Select"
          value={selectValue}
          onChange={(value?: string | number) => {
            if (typeof value === "number") setSelect(value);
          }}
          options={[
            { label: "Nichts", value: 0 },
            { label: "some", value: 1 },
            { label: "some 2", value: 2 },
            { label: "some 3", value: 3 },
          ]}
          info={showInfo && options.infoText}
          error={showError && options.errorText}
          searchAbel
        />
        <DateInput
          inline={inline}
          open={open}
          md={5}
          label="DateInput"
          value={dateValue}
          onChange={setDateValue}
          info={showInfo && options.infoText}
          error={showError && options.errorText}
        />
        <Checkbox
          inline={inline}
          md={5}
          label="Checkbox"
          value={checkboxValue}
          onChange={setCheckboxValue}
          info={(showInfo && options.infoText) || undefined}
          error={(showError && options.errorText) || undefined}
        />
        <UploadArea
          inline={inline}
          height="200px"
          onChange={(value: any) => {
            console.log(value);
          }}
          info={(showInfo && options.infoText) || undefined}
          error={(showError && options.errorText) || undefined}
        >
          UploadArea
        </UploadArea>
        <Typo variant="h3" color="primary" margin="0px">
          Buttons
        </Typo>
        <Button xs="100px" alignFloating={!inline}>
          Default
        </Button>
        <Button xs="100px" success alignFloating={!inline}>
          Success
        </Button>
        <Button xs="100px" warning alignFloating={!inline}>
          Warning
        </Button>
        <Button xs="100px" danger alignFloating={!inline}>
          Danger
        </Button>
        <Button xs="100px" tertiary alignFloating={!inline}>
          Tertiary
        </Button>
        <Button xs="100px" secondary alignFloating={!inline}>
          Secondary
        </Button>
        <Button xs="100px" primary alignFloating={!inline}>
          Primary
        </Button>
      </Formular>
    </Block>
  );
}

export default Form;
