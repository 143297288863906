import React from "react";
import { Row, Col, Typo } from "@maxeb/admin-ui";
import Documentation from "../DocumentationComponent";
import Example from "../DocumentationExample";
import FormExample from "./FormExample";

export interface IFlex {}

export default function FormDoc(props: IFlex) {
  return (
    <>
      <Typo variant="h1">Form</Typo>
      <Row>
        <Col lg={7}>
          <Typo variant="section">Documentation</Typo>
          <Documentation
            description={
              <>
                A form component should be used as wrapper around your inputs.
                It will create a {"<form>-Tag"} and also takes the role of a row
                element.
                <br />
                Therefore you can use all properties of the flex Row component
                on a form component.
              </>
            }
            extends={[{ label: "Row", to: "/flex/row" }]}
            properties={[
              {
                name: "onSubmit",
                default: (
                  <>
                    (e) {"=> {"}
                    <br />
                    <span style={{ paddingLeft: "20px" }}>
                      {"e.preventDefault();"}
                    </span>
                    <br />
                    {"}"}
                  </>
                ),
                type: ["(event: React.FormEvent<HTMLFormElement>) => void;"],
                description:
                  "A function that will trigger when forms onSubmit is triggered. If you use themes Buttons you need to set submit property, otherwise onSubmit will not be triggered.",
              },
            ]}
          />
          <Example
            label="Example"
            example={<FormExample />}
            language="typescript"
            code={
              'import React from "react";\nimport { Button, Form } from "@temeium/uui";\nexport default function FormExample() {\n  return (\n    <Form\n      vertical\n      spacing={16}\n      root={0}\n      horizontalAlign="center"\n      onSubmit={(e) => {\n        e.preventDefault();\n        alert("OnSubmit fired!");\n      }}\n    >\n      <Button xs="120px" primary submit>\n        Test\n      </Button>\n    </Form>\n  );\n}'
            }
          />
        </Col>
      </Row>
    </>
  );
}
