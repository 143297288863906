import React from "react";
import { useClasses, ITheme } from "@maxeb/admin-ui";

const styles = (theme: ITheme) => ({
  wrapper: {
    padding: "32px",
    color: "white",
    backgroundColor: theme.palette.get("success"), //get color succss from theme
    //get media query for display size sm down
    [theme.breakpoints.down("sm")]: {
      backgroundColor: theme.palette.get("danger"), //get color danger from theme
    },
    //get media query for display size lg up
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.get("primary"), //get color primary from theme
    },
  },
});

export default function MyResponsiveColorChanger() {
  const classes = useClasses(styles);
  return <div className={classes.wrapper}>Change Window size!</div>;
}
