import React, { useState } from "react";
import { Form, Checkbox } from "@maxeb/admin-ui";

export default function CheckboxExample() {
  const [value1, setValue1] = useState<boolean | undefined>();
  const [value2, setValue2] = useState<boolean | undefined>();
  const [value3, setValue3] = useState<boolean | undefined>();

  return (
    <Form spacing={16} root={0} parts={3}>
      <Checkbox
        sm={3}
        lg={1}
        label="Option 1"
        value={value1}
        onChange={setValue1}
      />
      <Checkbox
        sm={3}
        lg={1}
        label="Option 2"
        value={value2}
        onChange={setValue2}
      />
      <Checkbox
        sm={3}
        lg={1}
        label="Option 3"
        value={value3}
        onChange={setValue3}
      />
    </Form>
  );
}
