import React from "react";
import { Authenticator, Col, Row, Typo, useSession } from "@maxeb/admin-ui";

export default function FormDoc() {
  const [session, setSession] = useSession(null);

  return (
    <>
      <Typo variant="h1">Authentication</Typo>
      <Row>
        <Col lg={7}>
          <Authenticator session={session} setSession={setSession} />
        </Col>
      </Row>
    </>
  );
}
