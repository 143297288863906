import React from "react";
import { Block, Typo, Row, Col } from "@maxeb/admin-ui";
import CodeViewer from "./CodeViewer";

export interface IDocumetationExample {
  label?: string;
  example?: React.ReactNode;
  code?: string;
  language?: "typescript" | "css" | "bash";
}

export default function DocumentationExample(props: IDocumetationExample) {
  return (
    <React.Fragment>
      {typeof props.label !== "undefined" && (
        <Typo variant="section">{props.label}</Typo>
      )}
      <Block>
        <Row
          root={0}
          vertical
          spacing={16}
          horizontalAlign="right"
          verticalAlign="bottom"
        >
          {props.example}
          {typeof props.code !== "undefined" && (
            <Col>
              <Typo variant="h3" margin="0px 0px 8px 0px" color="primary">
                Code
              </Typo>
              <CodeViewer language={props.language || "typescript"}>
                {props.code}
              </CodeViewer>
            </Col>
          )}
        </Row>
      </Block>
    </React.Fragment>
  );
}
