import React from "react";
import { useClasses, ITheme, Image } from "@maxeb/admin-ui";

export interface IExampleImage {}

const styles = (theme: ITheme) => ({});

export default function Forms(props: IExampleImage) {
  const classes = useClasses(styles);
  return (
    <>
      <Image hash="eNDl{3~q9F9FIUWBayofj[j[00D%oft7xuayj[ofj[WBM{WBxuxut7" />
    </>
  );
}
