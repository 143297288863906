import React from "react";
import { Typo, Row, Col } from "@maxeb/admin-ui";
import DocumentationBlock, { IDocumetationBlock } from "./DocumentationBlock";

export interface ILists {}

const content: IDocumetationBlock[] = [
  {
    label: "Initialise Library",
    description: (
      <>
        First thing to do is to initialise the library in your Project.
        Following steps needs to be done in your index.ts (or index.js if you
        are not using typescript) of your react project.
      </>
    ),
    steps: [
      {
        label: <>import library init function.</>,
        code: 'import { init } from "@maxeb/admin-ui"',
      },
      {
        label: (
          <>
            Call imported init function. If you want to customize your theme you
            can set the needed options in the init() function. See{" "}
            <Typo variant="a" to="/customize">
              customize
            </Typo>{" "}
            section for more information.
          </>
        ),
        code: "const options = {};\ninit(options);",
      },
    ],
    exampleDescrtiption: <>index.ts</>,
    exampleCode:
      'import React from "react";\nimport ReactDOM from "react-dom";\nimport App from "./App";\nimport reportWebVitals from "./reportWebVitals";\nimport { init } from "@maxeb/admin-ui";\n\nconst options = {};\ninit(options);\n\nReactDOM.render(\n  <React.StrictMode>\n    <App />\n  </React.StrictMode>,\n  document.getElementById("root")\n);\n\nreportWebVitals();',
  },
  {
    label: "Set basic Sylesheets",
    language: "css",
    description: (
      <>
        All Components and Layouts use jss and ship theire styles with them.
        However font import, html- and body-tag definitions are done in App.css,
        so you have to modify it.
      </>
    ),
    steps: [
      {
        label: <>import font from google-fonts.</>,
        code: '@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap");',
      },
      {
        label: <>set font size in html tag.</>,
        code: "html {\n  font-size: 14px;\n}",
      },
      {
        label: <>set font, color and margin in body tag.</>,
        code: 'body {\n  background-color: rgb(236, 236, 236);\n  margin: 0;\n  font-size: 14px;\n  font-family: "Noto Sans", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  color: #3e3e3e;\n}',
      },
    ],
    exampleDescrtiption: <>App.css</>,
    exampleCode:
      '@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap");\n\n"html {\n  font-size: 14px;\n}"\n\nbody {\n  background-color: rgb(236, 236, 236);\n  margin: 0;\n  font-size: 14px;\n  font-family: "Noto Sans", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  color: #3e3e3e;\n}',
  },
  {
    label: "INITIALISE ROUTER (OPTIONAL)",
    description: (
      <>
        If you dont want to use managed layouts you don't have to do this part.
        However if you want to use them or want to use the routing components on
        your site, it is strongly advised to do this. All following steps are
        again done in the index.ts file of your react project.
      </>
    ),
    steps: [
      {
        label: <>Add Router component to your imports</>,
        code: 'import { init, Router } from "@maxeb/admin-ui"',
      },
      {
        label: <>Wrap the Router around the App component.</>,
        code: 'ReactDOM.render(\n  <React.StrictMode>\n    <Router>\n      <App />\n    </Router>\n  </React.StrictMode>,\n  document.getElementById("root")\n);',
      },
    ],
    exampleDescrtiption: <>index.ts</>,
    exampleCode:
      'import React from "react";\nimport ReactDOM from "react-dom";\nimport App from "./App";\nimport reportWebVitals from "./reportWebVitals";\nimport { init, Router } from "@maxeb/admin-ui";\n\nconst options = {};\ninit(options);\n\nReactDOM.render(\n  <React.StrictMode>\n    <Router>\n      <App />\n    </Router>\n  </React.StrictMode>,\n  document.getElementById("root")\n);\n\nreportWebVitals();',
  },
];

export default function Lists(props: ILists) {
  return (
    <>
      <Typo variant="h1">Initialisation</Typo>
      <Row root={0} spacing={16} vertical>
        <Col lg={7}>
          {content.map((section, index) => (
            <DocumentationBlock key={"content-" + index} {...section} />
          ))}
        </Col>
      </Row>
    </>
  );
}
