import React from "react";
import { faBook } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { List, ListItem } from "@maxeb/admin-ui";

export default function ListExampleCollapsable() {
  return (
    <List
      icon={<FontAwesomeIcon icon={faBook} />}
      label="Liste"
      collapseAble
      open
    >
      <ListItem>Element 1</ListItem>
      <ListItem>Element 2</ListItem>
      <ListItem>Element 3</ListItem>
    </List>
  );
}
