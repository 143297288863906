import React from "react";
import { Button, Form } from "@maxeb/admin-ui";

export default function FormExample() {
  return (
    <Form
      vertical
      spacing={16}
      root={0}
      horizontalAlign="center"
      onSubmit={(e) => {
        e.preventDefault();
        alert("OnSubmit fired!");
      }}
    >
      <Button xs="120px" primary submit>
        Test
      </Button>
    </Form>
  );
}
